import { Box, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import DurationSelector from "widgets/ZAnalytics/ZAnalyticsForecast/component/DurationSelector";
import { Select, SelectItem, Typography } from "zds";
import MeterSegmentationChart from "./MeterSegmentationChart";
import { getAggregationTypePeriodAndPeriodicity } from "widgets/ZAnalytics/util/DateUtil";
export type MetersList = {
  id: string;
  name: string;
}[];

type MetersSelectorProps = {
  meterList: MetersList;
  selectedMeterId: string;
  getSegmentation: any;
  segmentationData: any;
  mode: any;
  durationType: any;
};
const StyledSelect = styled.div`
  width: 200px;
`;
const MetersSelector = (props: MetersSelectorProps) => {
  const {
    meterList,
    selectedMeterId,
    getSegmentation,
    segmentationData,
    mode,
    durationType,
  } = props;
  const [selectedMetric, setSelectedMetric] = useState<string>("SUM");
  const handleDurationSelection = (
    filterType: any,
    durationType: any,
    selectedDuration: any,
  ) => {
    getSegmentation(selectedMeterId, durationType, selectedMetric);
  };

  const handleSelectedMeterIdChange = (meterId: any) => {
    getSegmentation(meterId, durationType);
  };
  const handleSelectedMetricChange = (event: any) => {
    const newMetric = event.target.value;
    setSelectedMetric(newMetric);
  };
  useEffect(() => {
    if (selectedMeterId)
      getSegmentation(selectedMeterId, durationType, selectedMetric);
  }, [selectedMeterId, selectedMetric]);
  return (
    <div>
      <Stack direction="column" spacing={2}>
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={2}
        >
          <DurationSelector
            handleDurationSelection={handleDurationSelection}
            currentDuration={durationType}
            durationType={
              getAggregationTypePeriodAndPeriodicity(durationType)
                .aggregationType
            }
          />
          <Typography
            variant="subtitle1"
            sx={{ textAlign: "left", fontSize: "13px" }}
          >
            Select Meter
          </Typography>
          <StyledSelect>
            <Select
              value={selectedMeterId}
              dsOnChange={(e) => handleSelectedMeterIdChange(e.target.value)}
              fullWidth
            >
              {meterList?.map((meter) => (
                <SelectItem key={meter.id} value={meter.id}>
                  {meter.name}
                </SelectItem>
              ))}
            </Select>
          </StyledSelect>
          <Typography
            variant="subtitle1"
            sx={{ textAlign: "left", fontSize: "13px" }}
          >
            Metric
          </Typography>
          <StyledSelect>
            <Select
              value={selectedMetric}
              dsOnChange={(e) => handleSelectedMetricChange(e)}
              fullWidth
            >
              <SelectItem value="COUNT">COUNT</SelectItem>
              <SelectItem value="SUM">SUM</SelectItem>
              <SelectItem value="AVG">AVERAGE</SelectItem>
              <SelectItem value="MIN">MIN</SelectItem>
              <SelectItem value="MAX">MAX</SelectItem>
            </Select>
          </StyledSelect>
        </Stack>
      </Stack>
      <Box mt={2}>
        <MeterSegmentationChart
          segmentationData={segmentationData}
          mode={mode}
          selectedMeterId={selectedMeterId}
          selectedMetric={selectedMetric}
          getSegmentation={getSegmentation}
        />
      </Box>
    </div>
  );
};

export default MetersSelector;
