import React, { useEffect, useState } from "react";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import type { CustomerAccounts } from "../types/accounts";
import type { Products } from "../types/products";
import { TypeAhead } from "zds";
import { Checkbox as MuiCheckBox, Box } from "@mui/material";
import { calculateDatesForPeriod } from "widgets/ZAnalytics/util/DateUtil";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export type AnalyseOptionType =
  | { accountId: string; accountName: string }
  | { productId: string; productName: string };

type AnalyseTypeAhead = {
  analyzeBy: "analyzeByProduct" | "analyzeByCustomer" | string;
  selectedObjects: CustomerAccounts | Products;
  setSelectedObjects: React.Dispatch<
    React.SetStateAction<CustomerAccounts | Products>
  >;
  customerOrProductData: any;
  getAccountsOrProducts: any;
  selectedDuration: any;
};

const AnalyseTypeAhead = (props: AnalyseTypeAhead) => {
  const {
    analyzeBy,
    selectedObjects,
    setSelectedObjects,
    customerOrProductData,
    getAccountsOrProducts,
    selectedDuration,
  } = props;
  const [query, setQuery] = useState("");

  const invalid = false;
  const getKey = () => {
    if (analyzeBy === "analyzeByCustomer") {
      return { id: "accountId", name: "accountName" };
    }
    return { id: "productId", name: "productName" };
  };
  useEffect(() => {
    setSelectedObjects([]);
    if (selectedDuration) {
      const { startDate, endDate } = calculateDatesForPeriod(
        selectedDuration,
        false,
      );
      getAccountsOrProducts(
        startDate,
        endDate,
        analyzeBy,
        null,
        null,
        true,
        query,
      );
    }
  }, [analyzeBy, selectedDuration]);

  useEffect(() => {
    if (query && query.length)
      getAccountsOrProducts(null, null, analyzeBy, null, null, true, query);
  }, [query]);

  const isChecked = (val: AnalyseOptionType[], id: string) => {
    if (!val) {
      return false;
    }
    return (
      val &&
      val?.some((value) => {
        if ("accountId" in value) {
          return id === value.accountId;
        }
        if ("productId" in value) {
          return id === value.productId;
        }
        return false;
      })
    );
  };
  return (
    <Box
      sx={{
        width: "350px",
        "& .MuiOutlinedInput-root.typeAhead-multiple": {
          padding: "1px",
        },
      }}
    >
      <TypeAhead
        e2e="customerSearchTypeAhead"
        getOptionLabel={(option: AnalyseOptionType) => {
          const key = getKey().name;
          return key in option ? `${option[key as keyof AnalyseOptionType]}` : "";
        }
        }
        placeholder={`${analyzeBy === "analyzeByCustomer" ? "Search for accounts" : "Search for products"}  `}
        multiple
        inputValue={query}
        options={customerOrProductData || []}
        inputChangeDebounce={300}
        dsOnChange={(_, val) => {
          if (val.length > 5) {
            alert({
              message: `You can select maximum 5 ${analyzeBy === "analyzeByCustomer" ? "accounts" : "products"
                }`,
              severity: "error",
              duration: 3000,
            });
          } else {
            setSelectedObjects([...val]);
          }
        }}
        dsOnInputChange={(_, val) => setQuery(val)}
        error={invalid}
        helperText={invalid ? "required" : ""}
        value={selectedObjects}
        dsRenderOption={(option) => {
          return (
            <div>
              <MuiCheckBox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={
                  isChecked(
                    selectedObjects,
                    option.accountId || option.productId,
                  ) || false
                }
              />
              {analyzeBy === "analyzeByCustomer"
                ? option.accountName
                : option.productName}
            </div>
          );
        }}
      />
    </Box>
  );
};

export default AnalyseTypeAhead;
