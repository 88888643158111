import { Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import DurationSelector from "widgets/ZAnalytics/ZAnalyticsForecast/component/DurationSelector";
import { Select, SelectItem, Typography } from "zds";
import AnalyseTypeAhead from "./AnalyseTypeAhead";
import { calculateDatesForPeriod } from "widgets/ZAnalytics/util/DateUtil";
import VerticalBarChart from "./VerticalChart";
import {
  DURATION_TYPE,
  DURATION_SELECT,
} from "widgets/ZAnalytics/util/constants";

type Props = {
  customerOrProductData: any;
  getAccountsOrProducts: any;
  riskOrOppData: any;
  getBillabelUsageOnAnalyzeChange: any;
  analyzeFilter: string;
  //duration: DURATION_TYPE;
  //durationType: DURATION_SELECT;
  duration: any;
  durationType: any;
};
export type analyzeBy = "analyzeByProduct" | "analyzeByCustomer";
const BillableUsage = (props: Props) => {
  const {
    customerOrProductData,
    getAccountsOrProducts,
    riskOrOppData,
    analyzeFilter,
    getBillabelUsageOnAnalyzeChange,
    duration,
    durationType,
  } = props;
  const [selectedDuration, setSelectedDuration] = useState(duration);
  const handleDurationSelection = (
    filterType: any,
    durationType: any,
    selectedDuration: any,
  ) => {
    const { endDate, startDate } = calculateDatesForPeriod(
      selectedDuration,
      false,
    );
    setSelectedDuration(selectedDuration);
    getAccountsOrProducts(
      startDate,
      endDate,
      analyzeFilter,
      duration,
      filterType.charAt(0).toUpperCase() + filterType.slice(1),
    );
  };
  const [selectedObjects, setSelectedObjects] = useState<any>([]);
  const handleAnalyzeFilterChange = (e: any) => {
    getBillabelUsageOnAnalyzeChange(e.target.value);
  };
  return (
    <>
      <Stack direction="column" spacing={2}>
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={2}
        >
          <DurationSelector
            handleDurationSelection={handleDurationSelection}
            currentDuration={duration}
            durationType={durationType}
          />
          <Typography variant="title">Analysed by:</Typography>
          <Select
            value={analyzeFilter}
            fullWidth={false}
            dsOnChange={handleAnalyzeFilterChange}
          >
            <SelectItem value="analyzeByProduct">
              <Typography>Product</Typography>
            </SelectItem>
            <SelectItem value="analyzeByCustomer">
              <Typography>Customer</Typography>
            </SelectItem>
          </Select>
          <AnalyseTypeAhead
            selectedObjects={selectedObjects}
            setSelectedObjects={setSelectedObjects}
            customerOrProductData={customerOrProductData}
            getAccountsOrProducts={getAccountsOrProducts}
            selectedDuration={selectedDuration}
            analyzeBy={analyzeFilter}
          />
        </Stack>
        <VerticalBarChart
          data={
            riskOrOppData?.data?.accountSortByRevenueList ||
            riskOrOppData?.data?.productByRevenueList ||
            []
          }
          loading={false}
        />
      </Stack>
    </>
  );
};

export default BillableUsage;
