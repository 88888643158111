import React from "react";
import styled from "@emotion/styled";
import { Icon, Typography } from "zds";

// Define prop types for styled component

// Use the defined prop types for styled component
const IndicatorStyle = styled.div`
  width: auto;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  padding-right: 4px;
`;

const ChangeIndicator = (props: { value: number; inverse?: boolean }) => {
  const { value, inverse = false } = props;

  // Determine the color and arrow direction based on value and inverse
  const isNegative = value < 0 ? true : false;
  const arrowDirection = isNegative ? "arrow_drop_down" : "arrow_drop_up";
  const iconColor = inverse
    ? isNegative
      ? "success"
      : "error"
    : isNegative
      ? "error"
      : "success";
  const backgroundColor = inverse
    ? isNegative
      ? "#E1F3E6"
      : "#FFE9E5"
    : isNegative
      ? "#FFE9E5"
      : "#E1F3E6";

  const color = inverse
    ? isNegative
      ? "#1B5E20"
      : "#B71C1C"
    : isNegative
      ? "#B71C1C"
      : "#1B5E20";

  return (
    <IndicatorStyle style={{ backgroundColor, color }}>
      <Icon body={arrowDirection} variant="filled" color={iconColor} />
      <Typography>
        {isNaN(value) || !isFinite(value) ? "--" : `${Math.abs(value)}%`}
      </Typography>
    </IndicatorStyle>
  );
};

export default ChangeIndicator;
