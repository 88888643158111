import React, { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { getEditorConfigs, getWidgets } from "sagas/selectors";
import { getLayoutSavePayload } from "ee/sagas/PageSagas";
import ActionAPI from "api/ActionAPI";
import PageApi from "api/PageApi";
import { riskOrOppPayload } from "../api/riskOrOpp";
import ChartRenderer from "./ChartRenderer";
import { getCurrentWorkspaceId } from "ee/selectors/selectedWorkspaceSelectors";
import { getSavedDatasources } from "ee/selectors/entitiesSelector";
import type { ApiAction } from "entities/Action";
import type {
  DURATION_SELECT,
  DURATION_TYPE,
} from "widgets/ZAnalytics/util/constants";

interface Props {
  chartType: string;
  onSizeChange: (size: string | number, chartType: string) => void;
  size: number | string;
  riskOrOppData: any;
  isLoading: boolean;
  fillColor: string;
  customerOrProductData: any;
  getAccountsOrProducts: any;
  getBillabelUsageOnAnalyzeChange: any;
  analyzeFilter: string;
  duration: DURATION_SELECT;
  durationType: DURATION_TYPE;
}

const ChartBaseComponent = (props: Props) => {
  const {
    chartType,
    fillColor,
    isLoading,
    onSizeChange,
    riskOrOppData,
    size,
    customerOrProductData,
    getAccountsOrProducts,
    getBillabelUsageOnAnalyzeChange,
    analyzeFilter,
    duration,
    durationType,
  } = props;
  const formattedChartData = useMemo(() => {
    if (chartType === "risk" || chartType === "opportunity") {
      return riskOrOppData?.content;
    }
    if (chartType === "accounts") {
      return riskOrOppData?.data?.accountSortByRevenueList;
    }
    if (chartType === "products") {
      return riskOrOppData?.data?.productByRevenueList;
    }
    return null;
  }, [riskOrOppData, chartType]);
  const widgets = useSelector(getWidgets);
  const editorConfigs = useSelector(getEditorConfigs);
  const savePageRequest = getLayoutSavePayload(widgets, editorConfigs);
  const workspaceId = useSelector(getCurrentWorkspaceId);
  const datasources = useSelector(getSavedDatasources);
  const [zuoraInternalDataSource] = datasources.filter((ds) => {
    return ds.name === "Zuora Default Store";
  });
  const pageWidgets = Object.keys(widgets);
  const filteredWidgets = pageWidgets
    .filter((key) => {
      return widgets[key].type === "ZAnalyticsChart_WIDGET";
    })
    .map((key) => widgets[key]);

  const createQuery = async (widgetId: string) => {
    if (
      editorConfigs &&
      editorConfigs?.pageId &&
      editorConfigs?.applicationId &&
      zuoraInternalDataSource
    ) {
      const timeStamp = Date.now();
      const { id, pluginId } = zuoraInternalDataSource;
      const apiMetaData: any[] = [
        {
          name: `riskOrOppData${timeStamp}`,
          httpMethod: "POST",
          path: "/{{this.params.path}}",
          queryParameters: [
            {
              key: "limit",
              value: "{{this.params.limit}}",
            },
            {
              key: "startDate",
              value: "{{this.params.startDate}}",
            },
            {
              key: "endDate",
              value: "{{this.params.endDate}}",
            },
            {
              key: "aggregationType",
              value: "{{this.params.aggregationType}}",
            },
            {
              key: "size",
              value: "{{this.params.size}}",
            },
            {
              key: "currencyIsoCode",
              value: "{{this.params.currencyIsoCode}}",
            },
            {
              key: "sumTrailingCustomer",
              value: "{{this.params.sumTrailingCustomer}}",
            },
          ],
          body: "{{this.params.body}}",
          jsonPathKeys: [
            "this.params.path",
            "this.params.size",
            "this.params.startDate",
            "this.params.endDate",
            "this.params.aggregationType",
            "this.params.body",
            "this.params.limit",
            "this.params.currencyIsoCode",
            "this.params.sumTrailingCustomer",
          ],
        },
        {
          name: `customerOrProductData${timeStamp}`,
          httpMethod: "GET",
          path: "/{{this.params.filterPath}}",
          body: "{{}}",
          jsonPathKeys: ["this.params.filterPath"],
        },
      ];
      const payloads: any[] = apiMetaData.map(
        async ({
          body,
          httpMethod,
          jsonPathKeys,
          name,
          path,
          queryParameters,
        }) => {
          return ActionAPI.createAPIAction(
            riskOrOppPayload(
              editorConfigs?.pageId,
              editorConfigs?.applicationId,
              workspaceId,
              pluginId,
              id,
              name,
              httpMethod,
              path,
              body,
              jsonPathKeys,
              queryParameters,
            ),
          ) as Partial<ApiAction>;
        },
      );
      Promise.all([...payloads]).then(async (res) => {
        if (res) {
          const index = savePageRequest.dsl.children.findIndex(
            (child: { widgetId: string }) => child.widgetId === widgetId,
          );
          savePageRequest.dsl.children[index].riskOrOppData =
            `{{riskOrOppData${timeStamp}}}`;
          savePageRequest.dsl.children[index].customerOrProductData =
            `{{customerOrProductData${timeStamp}}}`;
          savePageRequest.dsl.children[index].dynamicBindingPathList = [
            ...savePageRequest.dsl.children[index].dynamicBindingPathList,
            { key: "riskOrOppData" },
            { key: "customerOrProductData" },
          ];
          savePageRequest.dsl.children[index].dynamicPropertyPathList = [
            { key: "riskOrOppData" },
            { key: "customerOrProductData" },
          ];
          savePageRequest.dsl.children[index].getCustomerOrProducts =
            `{{customerOrProductData${timeStamp}.run({filterPath: filterPath})}}`;
          savePageRequest.dsl.children[index].onChartTypeChanged =
            `{{riskOrOppData${timeStamp}.run({\n  path: path,\n  size: size,\n  aggregationType: aggregationType,\n  startDate: startDate,\n
              currencyIsoCode: currencyIsoCode,\n sumTrailingCustomer: sumTrailingCustomer,\n
              endDate: endDate,\n\tlimit:limit,\n\tbody:body\n  // \"key\": \"value\",\n});}}`;
          await PageApi.savePage(savePageRequest);
          const currentUrl = window.location.href;
          const editIndex = currentUrl.indexOf("/widgets");
          if (editIndex !== -1) {
            const newUrl = currentUrl.substring(0, editIndex);
            window.history.replaceState(null, "", newUrl);
            window.location.reload();
          }
        }
      });
    }
  };
  useEffect(() => {
    for (const widgetProps of filteredWidgets) {
      if (!widgetProps.riskOrOppData) {
        if (editorConfigs) createQuery(widgetProps.widgetId);
      }
    }
  }, []);

  // eslint-disable-next-line @appsmith/named-use-effect
  useEffect(() => {
    if (chartType && size) {
      onSizeChange(size, chartType);
    }
  }, [chartType, size]);

  return (
    <div>
      {isLoading ? (
        <h1>Loading</h1>
      ) : (
        <ChartRenderer
          chartType={chartType}
          content={formattedChartData}
          fillColor={fillColor}
          size={size}
          customerOrProductData={customerOrProductData}
          getAccountsOrProducts={getAccountsOrProducts}
          getBillabelUsageOnAnalyzeChange={getBillabelUsageOnAnalyzeChange}
          analyzeFilter={analyzeFilter}
          riskOrOppData={riskOrOppData}
          duration={duration}
          durationType={durationType}
        />
      )}
    </div>
  );
};

export default ChartBaseComponent;
