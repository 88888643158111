import React, { useEffect, useState } from "react";
import {
  calculateChange,
  numberWithCommas,
  formatDateRange,
} from "../../util/cardUtil";
import { getPreviousMonthAndYear } from "../../util/DateUtil";
import { getRevenueCardDates } from "../../util/DateUtil";
import { InfoCard } from "./InfoCard";
import { REVENUE_TYPE } from "../../util/cardUtil";
import { Grid } from "@mui/material";
type RevenueCardProps = {
  revenueType: string;
  previousPeriodRevenue: any;
  currentPeriodRevenue: any;
  onRevenueTypeChanged: any;
  forecastRevenue: any;
};
const RevenueCard = ({
  revenueType,
  previousPeriodRevenue,
  currentPeriodRevenue,
  onRevenueTypeChanged,
  forecastRevenue,
}: RevenueCardProps) => {
  const [revenue, setRevenue] = useState<any>({});
  const { compareToEndDate, compareToStartDate, endDate, startDate } =
    getRevenueCardDates(revenueType);
  const getRevenueData = async () => {
    try {
      const change = calculateChange(
        previousPeriodRevenue.data.revenue,
        revenueType === REVENUE_TYPE.Forecast
          ? forecastRevenue.data.revenue
          : currentPeriodRevenue.data.revenue,
      );

      const dateText =
        "From " + formatDateRange(compareToStartDate, compareToEndDate);

      const subtitle =
        revenueType != REVENUE_TYPE.Forecast
          ? formatDateRange(startDate, endDate)
          : "";

      let tooltipText = "";

      switch (revenueType) {
        case REVENUE_TYPE.MonthToDate:
          tooltipText =
            "This includes the total billable usage generated from the beginning of the current month up to the current date.";
          break;
        case REVENUE_TYPE.LastMonthSamePeriod:
          tooltipText =
            "This metric shows the total billable usage generated during the same specific days in the previous month as the current month-to-date period.";
          break;
        case REVENUE_TYPE.Forecast:
          tooltipText =
            "This metric estimates the total expected billable usage for the entire current month, based on current trends, historical data, and predictive analytics models.";
          break;
        case REVENUE_TYPE.LastMonthTotal:
          tooltipText =
            "This metric represents the total billable usage accumulated over the entire previous month.";
          break;
        case REVENUE_TYPE.LastYearTotal:
          tooltipText =
            "This metric represents the total billable usage accumulated over the entire previous year.";
          break;
      }

      // const dateText =
      //   'From ' +
      //   convertYYYYMMDDToDDMMYYYY(compareToStartDate) +
      //   '-' +
      //   convertYYYYMMDDToDDMMYYYY(compareToEndDate);

      setRevenue({
        value: numberWithCommas(revenueType !== REVENUE_TYPE.Forecast ? currentPeriodRevenue.data.revenue :
          forecastRevenue.data.revenue, true),
        change: change,
        dateText: dateText,
        subtitle: subtitle,
        currency: currentPeriodRevenue.data.currency,
        tooltipText: tooltipText,
      });
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    const {
      compareToEndDate,
      compareToStartDate,
      endDate,
      startDate,
      aggregationType,
    } = getRevenueCardDates(revenueType);
    onRevenueTypeChanged(
      startDate,
      endDate,
      aggregationType,
      compareToStartDate,
      compareToEndDate,
    );
    getRevenueData();
  }, [revenueType]);

  useEffect(() => {
    getRevenueData();
  }, [previousPeriodRevenue, currentPeriodRevenue, forecastRevenue]);

  return (
    <Grid item md={4} sm={6} xs={12}>
      <InfoCard
        changeValue={revenue.change}
        currency={revenue.currency}
        dateText={revenue.dateText}
        subtitle={revenue.subtitle} // Ensure the subtitle is passed here
        title={
          revenueType === REVENUE_TYPE.MonthToDate
            ? "Month-to-Date Billable Usage"
            : revenueType === REVENUE_TYPE.LastMonthSamePeriod
              ? "Last Month's Billable Usage for the same time period "
              : revenueType === REVENUE_TYPE.LastMonthTotal
                ? "Last Month's Total Billable Usage "
                : revenueType === REVENUE_TYPE.Forecast
                  ? "Total Forecasted Billable Usage for Current Month "
                  : "Last Year's Total Billable Usage "
        }
        tooltipText={revenue.tooltipText}
        value={revenue.value}
      />
    </Grid>
  );
};

export default RevenueCard;
