import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getEditorConfigs, getWidgets } from "sagas/selectors";
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { getLayoutSavePayload } from "ce/sagas/PageSagas";
import ActionAPI, { type ActionCreateUpdateResponse } from "api/ActionAPI";
import PageApi from "api/PageApi";
import { getPrevMonthpayload } from "../api/payload";
import TotalAccountsCards from "./TotalAccountsCard";
import RevenueCard from "./RevenueCard";
import { getCurrentWorkspaceId } from "ee/selectors/selectedWorkspaceSelectors";
import { getSavedDatasources } from "ee/selectors/entitiesSelector";
type AnalyticsCardProps = {
  cardType: string;
  currentMonthTotalAccounts: any;
  currentPeriodRevenue: any;
  previousMonthTotalAccounts: any;
  previousPeriodRevenue: any;
  revenueType: string;
  onRevenueTypeChanged: any;
  forecastRevenue: any;

};
const AnalyticsCard = ({
  cardType,
  currentMonthTotalAccounts,
  currentPeriodRevenue,
  previousMonthTotalAccounts,
  previousPeriodRevenue,
  revenueType,
  onRevenueTypeChanged,
  forecastRevenue,
}: AnalyticsCardProps) => {
  const widgets = useSelector(getWidgets);
  const editorConfigs = useSelector(getEditorConfigs);
  const savePageRequest = getLayoutSavePayload(widgets, editorConfigs);
  const workspaceId = useSelector(getCurrentWorkspaceId);
  const pageWidgets = Object.keys(widgets);
  const datasources = useSelector(getSavedDatasources);
  const [zuoraInternalDataSource] = datasources.filter((ds) => {
    return ds.name === "Zuora Default Store";
  });
  const filteredWidgets = pageWidgets
    .filter((key) => {
      return widgets[key].type === "ZAnalyticsCard_WIDGET";
    })
    .map((key) => widgets[key]);

  const createQuery = async (widgetId: string) => {
    const timeStamp = Date.now();
    if (
      editorConfigs &&
      editorConfigs?.pageId &&
      editorConfigs?.applicationId &&
      zuoraInternalDataSource
    ) {

      const { id, pluginId } = zuoraInternalDataSource;
      const apiMetaData = [
        {
          name: "perviousMonthTotalAccounts" + timeStamp,
          httpMethod: "GET",
          formData: {
            apiContentType: "none",
          },
          path: "/analytics-consumption/customer/accounts/total",
          queryParameters: [{ key: "period", value: "0" }],
          body: "{}",
          jsonPathKeys: ["this.params.period"],
        },
        {
          name: "currentMonthTotalAccounts" + timeStamp,
          path: "/analytics-consumption/customer/accounts/total",
          httpMethod: "GET",
          formData: {
            apiContentType: "none",
          },
          queryParameters: [{ key: "period", value: "1" }],
          body: "{}",
          jsonPathKeys: ["this.params.period"],
        },
        {
          name: "currentPeriodRevenue" + timeStamp,
          path: "/analytics-consumption/usage/revenue",
          httpMethod: "POST",
          formData: {
            apiContentType: "application/json",
          },
          queryParameters: [
            {
              key: "aggregationType",
              value: "{{this.params.aggregationType}}",
            },
            { key: "startDate", value: "{{this.params.startDate}}" },
            { key: "endDate", value: "{{this.params.endDate}}" },
          ],
          body: "{}",
          jsonPathKeys: [
            "this.params.startDate",
            "this.params.endDate",
            "this.params.aggregationType",
          ],
        },
        {
          name: "previousPeriodRevenue" + timeStamp,
          path: "/analytics-consumption/usage/revenue",
          httpMethod: "POST",
          formData: {
            apiContentType: "application/json",
          },
          queryParameters: [
            {
              key: "aggregationType",
              value: "{{this.params.aggregationType}}",
            },
            { key: "startDate", value: "{{this.params.startDate}}" },
            { key: "endDate", value: "{{this.params.endDate}}" },
          ],
          body: "{}",
          jsonPathKeys: [
            "this.params.startDate",
            "this.params.endDate",
            "this.params.aggregationType",
          ],
        },
        {
          name: "forecastRevenue" + timeStamp,
          path: "/analytics-consumption/usage/revenue/forecast",
          httpMethod: "POST",
          formData: {
            apiContentType: "application/json",
          },
          queryParameters: [
            {
              key: "aggregationType",
              value: "monthly",
            },
            { key: "endDate", value: "{{this.params.endDate}}" },
            { key: "lookBackPeriod", value: "{{this.params.lookBackPeriod}}" },
          ],
          body: "{}",
          jsonPathKeys: [
            "this.params.lookBackPeriod",
            "this.params.endDate",
            "this.params.aggregationType",
            "this.params.forecastAggregationType"
          ],
        },
      ];
      const payloads = apiMetaData.map(
        ({ httpMethod, name, path, jsonPathKeys, body, queryParameters }) => {
          return ActionAPI.createAction(
            getPrevMonthpayload(
              editorConfigs?.pageId,
              editorConfigs?.applicationId,
              workspaceId,
              pluginId,
              id,
              name,
              httpMethod,
              path,
              body,
              jsonPathKeys,
              queryParameters,
            ),
          );
        },
      );

      Promise.all([...payloads]).then(async (res) => {
        const index = savePageRequest.dsl.children.findIndex(
          (child: { widgetId: string }) => child.widgetId === widgetId,
        );
        savePageRequest.dsl.children[index].perviousMonthTotalAccounts =
          `{{perviousMonthTotalAccounts${timeStamp}.data}}`;
        savePageRequest.dsl.children[index].currentMonthTotalAccounts =
          `{{currentMonthTotalAccounts${timeStamp}.data}}`;
        savePageRequest.dsl.children[index].currentPeriodRevenue =
          `{{currentPeriodRevenue${timeStamp}}}`;
        savePageRequest.dsl.children[index].previousPeriodRevenue =
          `{{previousPeriodRevenue${timeStamp}}}`;
        savePageRequest.dsl.children[index].forecastRevenue =
          `{{forecastRevenue${timeStamp}}}`;
        savePageRequest.dsl.children[index].dynamicBindingPathList = [
          ...savePageRequest.dsl.children[index].dynamicBindingPathList,
          { key: "perviousMonthTotalAccounts" },
          { key: "currentMonthTotalAccounts" },
          { key: "previousPeriodRevenue" },
          { key: "currentPeriodRevenue" },
          { key: "forecastRevenue" },
        ];
        savePageRequest.dsl.children[index].dynamicPropertyPathList = [
          { key: "perviousMonthTotalAccounts" },
          { key: "currentMonthTotalAccounts" },
          { key: "previousPeriodRevenue" },
          { key: "currentPeriodRevenue" },
          { key: "forecastRevenue" },
        ];

        savePageRequest.dsl.children[index].onRevenueTypeChanged =
          `{{previousPeriodRevenue${timeStamp}.run({\n  startDate: compareToStartDate,\n endDate: compareToEndDate,\n \n aggregationType: aggregationType,\n }); \n
        currentPeriodRevenue${timeStamp}.run({\n  startDate: startDate,\n endDate: endDate,\n \n aggregationType: aggregationType,\n }); \n
         forecastRevenue${timeStamp}.run({\n  lookBackPeriod: lookBackPeriod,\n endDate: endDate,\n \n aggregationType: "monthly"}); \n
        }}`;
        await PageApi.savePage(savePageRequest);
        const currentUrl = window.location.href;
        const editIndex = currentUrl.indexOf("/widgets");
        if (editIndex !== -1) {
          const newUrl = currentUrl.substring(0, editIndex);
          window.history.replaceState(null, "", newUrl);
          window.location.reload();
        }
      });
    }
  };
  // eslint-disable-next-line @appsmith/named-use-effect
  useEffect(() => {
    for (const widgetProps of filteredWidgets) {
      if (!widgetProps.perviousMonthTotalAccounts) {
        createQuery(widgetProps.widgetId);
      } else {
        onRevenueTypeChanged();
      }
    }
  }, [cardType, revenueType]);
  if (cardType === "revenue") {
    return (
      <div>
        <RevenueCard
          currentPeriodRevenue={currentPeriodRevenue}
          previousPeriodRevenue={previousPeriodRevenue}
          revenueType={revenueType}
          onRevenueTypeChanged={onRevenueTypeChanged}
          forecastRevenue={forecastRevenue}
        />
      </div>
    );
  }

  if (
    cardType === "totalAccounts" &&
    currentMonthTotalAccounts &&
    currentMonthTotalAccounts
  ) {
    return (
      <div>
        <TotalAccountsCards
          currentMonthTotalAccounts={currentMonthTotalAccounts}
          previousMonthTotalAccounts={previousMonthTotalAccounts}
        />
      </div>
    );
  }

  return <div>Loading</div>;
};

export default AnalyticsCard;
