import React from "react";

import type { DerivedPropertiesMap } from "WidgetProvider/factory";

import type { WidgetProps, WidgetState } from "widgets/BaseWidget";
import BaseWidget from "widgets/BaseWidget";
import { ValidationTypes } from "constants/WidgetValidation";
import { WIDGET_TAGS } from "constants/WidgetConstants";
import IconSVG from "../../ZAnanlyticsChart/icon.svg";
import ThumbnailSVG from "../../ZAnanlyticsChart/icon.svg";
import styled from "styled-components";
import { AutocompleteDataType } from "../../../../utils/autocomplete/AutocompleteDataType";
import type { SetterConfig, Stylesheet } from "../../../../entities/AppTheming";
import type { AutoLayoutConfig } from "../../../../WidgetProvider/constants";
import {
  COMPARE_TO_TYPE,
  DURATION_SELECT,
  DURATION_TYPE,
} from "widgets/ZAnalytics/util/constants";
import {
  calculateDatesForPeriod,
  getAggregationTypePeriodAndPeriodicity,
  getCompareToDate,
  getForecastedRevenueSeasonality,
  getPeriodicity,
} from "widgets/ZAnalytics/util/DateUtil";
import ForecastBaseComponent from "../component/ForecastBaseComponent";
import { createMessage } from "ee/constants/messages";
import { DROPDOWN_VARIANT } from "components/editorComponents/WidgetQueryGeneratorForm/CommonControls/DatasourceDropdown/types";
import {
  JSON_FORM_CONNECT_BUTTON_TEXT,
  SUCCESSFULL_BINDING_MESSAGE,
} from "widgets/JSONFormWidget/constants/messages";
import { FieldOptionsType } from "components/editorComponents/WidgetQueryGeneratorForm/WidgetSpecificControls/OtherFields/Field/Dropdown/types";
import { sourceDataValidationFn } from "widgets/ZAnalytics/ZAnanlyticsChart/widget";
import { EvaluationSubstitutionType } from "ee/entities/DataTree/types";
import { EventType } from "constants/AppsmithActionConstants/ActionConstants";
import { formatForecastedRevenueData } from "../util/forecastRevenue";

/*
FORECAST

analytics-consumption/forecast?periods=11&seasonality=D&includeNegatives=false
analytics-consumption/anomalies/AGILE
*/
const ContainerWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: auto;
  width: 100%;
  justify-content: flex-start;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.87);
`;
class ZAnalyticsForecastWidget extends BaseWidget<
  ZAnalyticsForecastWidgetProps,
  WidgetState
> {
  static type = "ZAnalyticForecast_Widget";
  static queryDataConfig = {
    controlConfig: {
      showEditFieldsModal: true, // Shows edit field modals button in the datasource table control
      datasourceDropdownVariant: DROPDOWN_VARIANT.CREATE_OR_EDIT_RECORDS, // Decides the variant of the datasource dropdown which alters the text and some options
      actionButtonCtaText: createMessage(JSON_FORM_CONNECT_BUTTON_TEXT), // CTA text for the connect action button in property pane
      excludePrimaryColumnFromQueryGeneration: true, // Excludes the primary column from the query generation by default
      isConnectableToWidget: true, // Whether this widget can be connected to another widget like Table,List etc
      alertMessage: {
        success: {
          update: createMessage(SUCCESSFULL_BINDING_MESSAGE, "updated"),
        }, // Alert message to show when the binding is successful
      },
      /* other form config options like create or update flow, get default values from widget and data identifier to be used in the generated query as primary key*/
      otherFields: [
        {
          label: "Form Type",
          name: "formType",
          // fieldType: FieldType.SELECT,
          optionType: FieldOptionsType.CUSTOM, // Dropdown options can be custom ( options provided by the widget config like Line 193 ) or widgets ( connectable widgets in the page ) or columns ( columns from the datasource )
          isRequired: true,
          getDefaultValue: () => {
            return "create";
          },
          allowClear: false, // whether the dropdown should have a clear option
          options: [
            {
              label: "Create records",
              value: "create",
              id: "create",
            },
            {
              label: "Edit records",
              value: "edit",
              id: "edit",
            },
          ],
          isVisible: (config: Record<string, any>) => {
            // Whether the field should be visible or not based on the config
            return config?.tableName !== "";
          },
        },
      ],
    },
    isJSConvertible: true,
    placeholderText: '{ "name": "John", "age": 24 }',
    isBindProperty: true,
    isTriggerProperty: false,
    validation: {
      type: ValidationTypes.FUNCTION,
      params: {
        fn: sourceDataValidationFn,
        expected: {
          type: "JSON",
          example: `{ "name": "John Doe", "age": 29 }`,
          autocompleteDataType: AutocompleteDataType.OBJECT,
        },
      },
    },
    evaluationSubstitutionType: EvaluationSubstitutionType.SMART_SUBSTITUTE,
  };

  static getConfig() {
    return {
      name: "ZAnalytics Forecast",
      tags: [WIDGET_TAGS.ZUORA],
      iconSVG: IconSVG,
      needsMeta: false,
      isCanvas: false,
      thumbnailSVG: ThumbnailSVG,
    };
  }

  static getFeatures() {
    return {
      dynamicHeight: {
        sectionIndex: 0, // Index of the property pane "General" section
        active: false,
      },
    };
  }

  static getSeasonality(selectedTimePeriod: string) {
    let seasonality = "";
    switch (selectedTimePeriod) {
      case "DAY":
        seasonality = "D";
        break;
      case "MONTH":
        seasonality = "M";
        break;
      case "WEEK":
        seasonality = "W";
        break;
      case "YEAR":
        seasonality = "Y";
        break;
      default:
        break;
    }
    return seasonality;
  }

  static getDefaults() {
    const { aggregationType, period } = getAggregationTypePeriodAndPeriodicity(
      DURATION_TYPE.twentyOneDays,
    );
    const { endDate, startDate } = calculateDatesForPeriod(
      DURATION_TYPE.twentyOneDays,
      false,
    );
    const compareTo = COMPARE_TO_TYPE.none;
    const { compareToStartDate, compareToEndDate } = getCompareToDate(
      startDate,
      endDate,
      compareTo,
      getPeriodicity(DURATION_TYPE.twentyOneDays),
    );
    const seasonality = ZAnalyticsForecastWidget.getSeasonality(
      getForecastedRevenueSeasonality(DURATION_TYPE.twentyOneDays),
    );
    return {
      widgetName: "ZAnalyticsForecast",
      rows: 4,
      columns: 4,
      version: 1,
      color: "primary",
      thickness: 3,
      isVisible: true,
      size: "30",
      startDate,
      endDate,
      compareToStartDate,
      compareToEndDate,
      aggregationType,
      path: "analytics-consumption/usage/revenue",
      forecastPath: "analytics-consumption/forecast",
      anomalyPath: "analytics-consumption/anomalies/AGILE",
      period,
      duration: DURATION_TYPE.twentyOneDays,
      durationType: DURATION_SELECT.daily,
      seasonality,
      periods: 0,
      forecastPayload: {},
      showForecast: false,
      payload: {
        accountIds: [],
        productIds: [],
      },
    };
  }

  static getAutoLayoutConfig(): AutoLayoutConfig | null {
    return {};
  }

  static getSetterConfig(): SetterConfig {
    return {
      __setters: {
        setVisibility: {
          path: "isVisible",
          type: "boolean",
        },
      },
    };
  }

  static getPropertyPaneContentConfig() {
    return [
      {
        sectionName: "General",
        children: [
          {
            propertyName: "revenueData",
            helpText: "Input JSON sample revenue data",
            label: "Revenue Source data",
            controlType: "ONE_CLICK_BINDING_CONTROL",
            ...ZAnalyticsForecastWidget.queryDataConfig,
          },
          {
            propertyName: "revenueCompareToData",
            helpText: "Input JSON sample revenue compare data",
            label: "Compare Revenue Source data",
            controlType: "ONE_CLICK_BINDING_CONTROL",
            ...ZAnalyticsForecastWidget.queryDataConfig,
          },
          {
            propertyName: "anomalyData",
            helpText: "Input JSON sample revenue compare data",
            label: "Revenue Anomaly Source data",
            controlType: "ONE_CLICK_BINDING_CONTROL",
            ...ZAnalyticsForecastWidget.queryDataConfig,
          },
          {
            propertyName: "forecastData",
            helpText: "Input JSON sample revenue compare data",
            label: "Revenue Forecast Source data",
            controlType: "ONE_CLICK_BINDING_CONTROL",
            ...ZAnalyticsForecastWidget.queryDataConfig,
          },
          {
            propertyName: "currentPeriodRevenueData",
            helpText: "Input JSON sample revenue compare data",
            label: "Current Period Revenue",
            controlType: "ONE_CLICK_BINDING_CONTROL",
            ...ZAnalyticsForecastWidget.queryDataConfig,
          },
          {
            propertyName: "previousPeriodRevenueData",
            helpText: "Input JSON sample revenue compare data",
            label: "Current Period Revenue",
            controlType: "ONE_CLICK_BINDING_CONTROL",
            ...ZAnalyticsForecastWidget.queryDataConfig,
          },
        ],
      },
      {
        sectionName: "event",
        children: [
          {
            helpText: "when chart is loaded",
            propertyName: "onChartLoad",
            label: "When chart is loaded",
            controlType: "ACTION_SELECTOR",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: true,
          },
          {
            helpText: "when compare to is changed",
            propertyName: "onCompareToChanged",
            label: "When compare to is changed",
            controlType: "ACTION_SELECTOR",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: true,
          },
          {
            helpText: "when Forecast is enabled",
            propertyName: "onForecastEnabled",
            label: "When forecast is enabled",
            controlType: "ACTION_SELECTOR",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: true,
          },
          {
            helpText: "when Anomaly is enabled",
            propertyName: "onAnomalyEnabled",
            label: "When forecast is enabled",
            controlType: "ACTION_SELECTOR",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: true,
          },
        ],
      },
    ];
  }

  fetchCompareToData = (
    duration = DURATION_TYPE.twentyOneDays,
    compareTo = COMPARE_TO_TYPE.none,
  ) => {
    const { aggregationType, period } =
      getAggregationTypePeriodAndPeriodicity(duration);
    const { endDate, startDate } = calculateDatesForPeriod(duration, false);
    const { compareToStartDate, compareToEndDate } = getCompareToDate(
      startDate,
      endDate,
      compareTo,
      getPeriodicity(duration),
    );
    this.updateWidgetProperty("compareTo", compareTo);
    if (this.props.onChartLoad && compareTo !== COMPARE_TO_TYPE.none) {
      this.updateWidgetProperty("payload", {
        accountIds: [],
        productIds: [],
      });
      this.updateWidgetProperty("endDate", endDate);
      this.updateWidgetProperty("startDate", startDate);
      this.updateWidgetProperty("period", period);
      this.updateWidgetProperty("aggregationType", aggregationType);
      this.updateWidgetProperty("duration", duration);
      this.updateWidgetProperty("compareToStartDate", compareToStartDate);
      this.updateWidgetProperty("compareToEndDate", compareToEndDate);
      super.executeAction({
        triggerPropertyName: "onCompareToChanged",
        dynamicString: this.props.onCompareToChanged,
        event: {
          type: EventType.ON_CHANGE,
        },
        globalContext: {
          path: this.props.path,
          payload: {
            accountIds: [],
            productIds: [],
          },
          startDate: startDate || this.props.startDate,
          endDate: endDate || this.props.endDate,
          aggregationType: aggregationType || this.props.aggregationType,
          period: period || this.props.period,
          duration: duration || this.props.duration,
          compareToEndDate: compareToEndDate,
          compareToStartDate: compareToStartDate,
          compareTo: compareTo || this.props.compareTo
        },
      });
    }
  };

  setCompareTo = (compareTo = COMPARE_TO_TYPE.none) => {
    this.updateWidgetProperty('compareTo', compareTo)
  }

  setShowForecast = (showForecast: boolean) => {
    this.updateWidgetProperty('showForecast', showForecast);
    if (showForecast) {
      if (this.props.revenueData?.data) {
        const { forecastPayload, periods, seasonality } = formatForecastedRevenueData(this.props.revenueData?.data, this.props.duration)
        if (forecastPayload && periods && seasonality)
          this.getForecast(forecastPayload, periods, seasonality);
      }
    }
  }

  onChartLoaded = (
    durationType = DURATION_SELECT.daily,
    duration = DURATION_TYPE.twentyOneDays,
  ) => {
    const { aggregationType, period } =
      getAggregationTypePeriodAndPeriodicity(duration);
    const { endDate, startDate, compareToStartDate, compareToEndDate } = calculateDatesForPeriod(duration, false);
    if (this.props.onChartLoad) {
      this.updateWidgetProperty("payload", {
        accountIds: [],
        productIds: [],
      });
      this.updateWidgetProperty("endDate", endDate);
      this.updateWidgetProperty("startDate", startDate);
      this.updateWidgetProperty("period", period);
      this.updateWidgetProperty("aggregationType", aggregationType);
      this.updateWidgetProperty("durationType", String(durationType).charAt(0).toUpperCase() + String(durationType).slice(1));
      this.updateWidgetProperty("duration", duration);
      this.updateWidgetProperty("compareToStartDate", compareToStartDate);
      this.updateWidgetProperty("compareToEndDate", compareToEndDate);
      super.executeAction({
        triggerPropertyName: "onChartLoad",
        dynamicString: this.props.onChartLoad,
        event: {
          type: EventType.ON_CHANGE,
        },
        globalContext: {
          path: this.props.path,
          payload: {
            accountIds: [],
            productIds: [],
          },
          startDate: startDate || this.props.startDate,
          endDate: endDate || this.props.endDate,
          durationType: durationType || this.props.durationType,
          aggregationType: aggregationType || this.props.aggregationType,
          period: period || this.props.period,
          duration: duration || this.props.duration,
          compareToStartDate: compareToStartDate || this.props.compareToStartDate,
          compareToEndDate: compareToEndDate || this.props.compareToEndDate,
        },
      });
    }
  };

  getForecast = (forecastPayload: any, periods: any, seasonality: any) => {
    this.updateWidgetProperty("forecastPayload", forecastPayload);
    this.updateWidgetProperty("periods", periods);
    this.updateWidgetProperty("seasonality", seasonality);
    if (this.props.onForecastEnabled) {
      super.executeAction({
        triggerPropertyName: "onForecastEnabled",
        dynamicString: this.props.onForecastEnabled,
        event: {
          type: EventType.ON_CHANGE,
        },
        globalContext: {
          forecastPath: this.props.forecastPath,
          seasonality: seasonality,
          periods: periods,
          forecastPayload: forecastPayload,
        },
      });
    }
    if (this.props.onAnomalyEnabled) {
      super.executeAction({
        triggerPropertyName: "onAnomalyEnabled",
        dynamicString: this.props.onAnomalyEnabled,
        event: {
          type: EventType.ON_CHANGE,
        },
        globalContext: {
          anomalyPath: this.props.anomalyPath,
          forecastPayload: forecastPayload,
        },
      });
    }
  };

  static getStylesheetConfig(): Stylesheet {
    return {
      fillColor: "{{appsmith.theme.colors.primaryColor}}",
    };
  }

  static getPropertyPaneStyleConfig() {
    return [];
  }

  static getDerivedPropertiesMap(): DerivedPropertiesMap {
    return {};
  }

  static getDefaultPropertiesMap(): Record<string, string> {
    return {};
  }

  static getMetaPropertiesMap(): Record<string, any> {
    return {};
  }

  getWidgetView() {
    const ZAnalyticsForecastWidgetProps = {
      ...this.props,
    };

    return (
      <ContainerWrapper>
        {/* <CircularProgress
          {...ZAnalyticsForecastWidgetProps}
          sx={{ color: this.props.fillColor }}
        /> */}
        <ForecastBaseComponent
          onChartLoaded={this.onChartLoaded}
          revenueData={this.props.revenueData?.data}
          compareToData={this.props.compareTo === COMPARE_TO_TYPE.none ?
            [] : this.props.revenueCompareToData?.data}
          duration={this.props.duration}
          durationType={this.props.durationType}
          fetchCompareToData={this.fetchCompareToData}
          getForecast={this.getForecast}
          forecastData={this.props.forecastData}
          anomalyData={this.props.anomalyData}
          previousPeriodRevenue={this.props.previousPeriodRevenueData?.data}
          currentPeriodRevenue={this.props.currentPeriodRevenueData?.data}
          endDate={this.props.endDate}
          startDate={this.props.startDate}
          compareToStartDate={this.props.compareToStartDate}
          compareToEndDate={this.props.compareToEndDate}
          isLoading={this.props.forecastData?.isLoading
            || this.props.anomalyData?.isLoading || this.props.previousPeriodRevenueData?.isLoading
            || this.props.currentPeriodRevenueData?.isLoading || this.props.revenueData?.isLoading
          }
          compareTo={this.props.compareTo}
          setCompareTo={this.setCompareTo}
          setShowForecast={this.setShowForecast}
          showForecast={this.props.showForecast}
        />
      </ContainerWrapper>
    );
  }
}

export interface ZAnalyticsForecastWidgetProps extends WidgetProps {
  size: number | string;
  thickness?: number;
  fillColor: string;
}

export default ZAnalyticsForecastWidget;
