import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  IconButton,
  Stack,
  Tooltip,
  Divider,
  Select,
  MenuItem,
} from "@mui/material";
import { StaticDatePicker } from "@mui/x-date-pickers";
import { Popover, Typography } from "zds";
import {
  COMPARE_TO_TYPE,
  DURATION_SELECT,
  DURATION_TYPE,
} from "widgets/ZAnalytics/util/constants";
import {
  getAggregationTypePeriodAndPeriodicity,
  getForecastedRevenueSeasonality,
  getPeriodicity,
  minusDays,
} from "widgets/ZAnalytics/util/DateUtil";

type CompareToSelectorProps = {
  duration: DURATION_TYPE | undefined;
  showForecastOnOverview: boolean;
  fetchCompareToData: any;
  compareTo: any;
  setCompareTo: any;
  showForecast: boolean;
  setShowForecast: any;
};

const CompareToSelector = (props: CompareToSelectorProps) => {
  const {
    duration,
    showForecastOnOverview,
    fetchCompareToData,
    compareTo,
    setCompareTo,
    showForecast,
    setShowForecast,
  } = props;
  const selectRef = useRef(null); // Create a ref for the Select component
  const [anchorEl, setAnchorEl] = useState(null);
  const [customEndDate, setCustomEndDate] = useState<Date>();
  const [selectOpen, setSelectOpen] = useState(false);
  const { aggregationType } = getAggregationTypePeriodAndPeriodicity(duration);
  const [lastPeriodicity, setLastPeriodicity] = useState<DURATION_SELECT>(
    getPeriodicity(duration),
  );
  const handleCompareToChange = (event: { target: { value: any } }) => {
    const selectedValue = event.target.value;
    if (selectedValue === "Custom End Date") {
      setAnchorEl(selectRef.current); // Open the date picker
    } else {
      setCustomEndDate(undefined);
      setCompareTo(selectedValue);
    }
  };
  const handleCloseDatePicker = () => {
    setCustomEndDate(undefined);
    setAnchorEl(null);
  };
  const formatDateFromString = (date: any) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return date.toLocaleDateString("en-US", options);
  };
  const handleDatePick = (date: any) => {
    setCustomEndDate(date);
    const formattedDate: any = formatDateFromString(date);
    setCompareTo(formattedDate);
    setAnchorEl(null);
  };

  const handleClick = () => {
    if (!showForecast) {
      setCompareTo(COMPARE_TO_TYPE.none);
    }
    setShowForecast(!showForecast);
  };

  useEffect(() => {
    const newPeriodicity = getPeriodicity(duration);
    if (
      compareTo != COMPARE_TO_TYPE.none &&
      newPeriodicity === lastPeriodicity
    ) {
      //fetchCompareToData();
      fetchCompareToData(duration, compareTo);
      setShowForecast(false);
    } else {
      setCompareTo(COMPARE_TO_TYPE.none);
      //setCompareToData({});
      setCustomEndDate(undefined);
    }
    setLastPeriodicity(newPeriodicity);
  }, [duration, compareTo]);

  return (
    <div>
      <Stack
        spacing={1}
        direction="row"
        divider={<Divider orientation="vertical" flexItem />}
      >
        <Stack direction="row" spacing={1} alignItems="center">
          <Typography>Compare To:</Typography>
          <Select
            sx={{
              fontSize: "14px",
              fontFamily: "Arial, sans-serif", // Ensure the same font type
              width: "200px",
            }}
            onChange={handleCompareToChange}
            value={compareTo || "Select One"}
            placeholder="Select One"
            ref={selectRef}
            open={selectOpen}
            onOpen={() => setSelectOpen(true)}
            onClose={() => setSelectOpen(false)}
            renderValue={(v) => v}
          >
            <MenuItem key={COMPARE_TO_TYPE.none} value={COMPARE_TO_TYPE.none}>
              {COMPARE_TO_TYPE.none}
            </MenuItem>
            {aggregationType.toUpperCase() ===
              DURATION_SELECT.daily.toUpperCase() && (
                <MenuItem
                  key={COMPARE_TO_TYPE.prevDay}
                  value={COMPARE_TO_TYPE.prevDay}
                >
                  {COMPARE_TO_TYPE.prevDay}
                </MenuItem>
              )}
            {(aggregationType.toUpperCase() ===
              DURATION_SELECT.daily.toUpperCase() ||
              aggregationType.toUpperCase() ===
              DURATION_SELECT.weekly.toUpperCase()) && (
                <MenuItem
                  key={COMPARE_TO_TYPE.prevWeek}
                  value={COMPARE_TO_TYPE.prevWeek}
                >
                  {COMPARE_TO_TYPE.prevWeek}
                </MenuItem>
              )}
            {(aggregationType.toUpperCase() ===
              DURATION_SELECT.daily.toUpperCase() ||
              aggregationType.toUpperCase() ===
              DURATION_SELECT.weekly.toUpperCase() ||
              aggregationType.toUpperCase() ===
              DURATION_SELECT.monthly.toUpperCase()) && (
                <MenuItem
                  key={COMPARE_TO_TYPE.prevMonth}
                  value={COMPARE_TO_TYPE.prevMonth}
                >
                  {COMPARE_TO_TYPE.prevMonth}
                </MenuItem>
              )}
            <MenuItem
              key={COMPARE_TO_TYPE.prevYear}
              value={COMPARE_TO_TYPE.prevYear}
            >
              {COMPARE_TO_TYPE.prevYear}
            </MenuItem>
            {aggregationType.toUpperCase() ===
              DURATION_SELECT.daily.toUpperCase() && (
                <MenuItem
                  key={COMPARE_TO_TYPE.customEndDate}
                  value={COMPARE_TO_TYPE.customEndDate}
                >
                  {COMPARE_TO_TYPE.customEndDate}
                </MenuItem>
              )}
          </Select>
          {/* <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            dsOnClose={handleCloseDatePicker}
            a11yId="date-picker"
          >
            <StaticDatePicker
              maxDate={minusDays(new Date(), 3)}
              value={customEndDate}
              onAccept={handleDatePick}
              onClose={setAnchorEl(null)}
            />
          </Popover> */}
        </Stack>
        <Button
          variant="outlined"
          onClick={() => {
            handleClick();
          }}
          sx={{
            fontSize: "14px", // Matching font size
            fontFamily: "Arial, sans-serif", // Matching font type
            backgroundColor: showForecast ? "#EDF6FF" : "transparent",
            color: showForecast ? "blue" : "black",
            borderColor: showForecast ? "blue" : "#A4A4A4",
            borderWidth: showForecast ? "2px" : "1px", // 2px when selected, 1px otherwise
            "&:hover": {
              backgroundColor: "rgba(0, 0, 255, 0.1)", // Light blue tint on hover
              borderColor: "blue",
              borderWidth: "2px", // 2px on hover
            },
          }}
        >
          Anomaly + Forecast
        </Button>
      </Stack>
    </div>
  );
};

export default CompareToSelector;
