import React from "react";

import type { DerivedPropertiesMap } from "WidgetProvider/factory";

import type { WidgetProps, WidgetState } from "widgets/BaseWidget";
import BaseWidget from "widgets/BaseWidget";
import { WIDGET_TAGS } from "constants/WidgetConstants";
import IconSVG from "../../ZAnanlyticsChart/icon.svg";
import ThumbnailSVG from "../../ZAnanlyticsChart/icon.svg";
import styled from "styled-components";
import { AutocompleteDataType } from "../../../../utils/autocomplete/AutocompleteDataType";
import type { SetterConfig, Stylesheet } from "../../../../entities/AppTheming";
import type { AnvilConfig, AutoLayoutConfig } from "../../../../WidgetProvider/constants";
import { DROPDOWN_VARIANT } from "components/editorComponents/WidgetQueryGeneratorForm/CommonControls/DatasourceDropdown/types";
import { createMessage } from "ee/constants/messages";
import {
  JSON_FORM_CONNECT_BUTTON_TEXT,
  SUCCESSFULL_BINDING_MESSAGE,
} from "widgets/JSONFormWidget/constants/messages";
import { FieldOptionsType } from "components/editorComponents/WidgetQueryGeneratorForm/WidgetSpecificControls/OtherFields/Field/Dropdown/types";
import { EvaluationSubstitutionType } from "ee/entities/DataTree/types";
import {
  ValidationTypes,
  type ValidationResponse,
} from "constants/WidgetValidation";
import { getRevenueCardDates } from "../.././util/DateUtil";
import AnalyticsCard from "../components/AnalyticsCard";
import { sourceDataValidationFn } from "widgets/JSONFormWidget/widget/propertyConfig";
import { EventType } from "constants/AppsmithActionConstants/ActionConstants";
import { Box } from "@mui/material";
import { Spinner } from "zds";

const ContainerWrapper = styled.div`

`;
class ZAnalyticsCardWidget extends BaseWidget<
  ZAnalyticsCardWidgetProps,
  WidgetState
> {
  static type = "ZAnalyticsCard_WIDGET";

  static queryDataConfig = {
    controlConfig: {
      showEditFieldsModal: true, // Shows edit field modals button in the datasource table control
      datasourceDropdownVariant: DROPDOWN_VARIANT.CREATE_OR_EDIT_RECORDS, // Decides the variant of the datasource dropdown which alters the text and some options
      actionButtonCtaText: createMessage(JSON_FORM_CONNECT_BUTTON_TEXT), // CTA text for the connect action button in property pane
      excludePrimaryColumnFromQueryGeneration: true, // Excludes the primary column from the query generation by default
      isConnectableToWidget: true, // Whether this widget can be connected to another widget like Table,List etc
      alertMessage: {
        success: {
          update: createMessage(SUCCESSFULL_BINDING_MESSAGE, "updated"),
        }, // Alert message to show when the binding is successful
      },
      /* other form config options like create or update flow, get default values from widget and data identifier to be used in the generated query as primary key*/
      otherFields: [
        {
          label: "Form Type",
          name: "formType",
          // fieldType: FieldType.SELECT,
          optionType: FieldOptionsType.CUSTOM, // Dropdown options can be custom ( options provided by the widget config like Line 193 ) or widgets ( connectable widgets in the page ) or columns ( columns from the datasource )
          isRequired: true,
          getDefaultValue: () => {
            return "create";
          },
          allowClear: false, // whether the dropdown should have a clear option
          options: [
            {
              label: "Create records",
              value: "create",
              id: "create",
            },
            {
              label: "Edit records",
              value: "edit",
              id: "edit",
            },
          ],
          isVisible: (config: Record<string, any>) => {
            // Whether the field should be visible or not based on the config
            return config?.tableName !== "";
          },
        },
      ],
    },
    isJSConvertible: true,
    placeholderText: '{ "name": "John", "age": 24 }',
    isBindProperty: true,
    isTriggerProperty: false,
    validation: {
      type: ValidationTypes.FUNCTION,
      params: {
        fn: sourceDataValidationFn,
        expected: {
          type: "JSON",
          example: `{ "name": "John Doe", "age": 29 }`,
          autocompleteDataType: AutocompleteDataType.OBJECT,
        },
      },
    },
    evaluationSubstitutionType: EvaluationSubstitutionType.SMART_SUBSTITUTE,
  };

  static getConfig() {
    return {
      name: "ZAnalytics Card Widget",
      tags: [WIDGET_TAGS.ZUORA],
      iconSVG: IconSVG,
      needsMeta: false,
      isCanvas: false,
      thumbnailSVG: ThumbnailSVG,
    };
  }

  static getFeatures() {
    return {
      dynamicHeight: {
        sectionIndex: 0, // Index of the property pane "General" section
        active: true,
      },
    };
  }

  static getDefaults() {
    const {
      aggregationType,
      compareToEndDate,
      compareToStartDate,
      endDate,
      startDate,
    } = getRevenueCardDates("LastMonthTotal");
    const forecastAggregationType = "monthly";
    return {
      widgetName: "ZAnalyticsCard",
      rows: 15,
      columns: 30,
      version: 1,
      color: "primary",
      thickness: 3,
      isVisible: true,
      size: "30",
      revenueType: "LastMonthTotal",
      cardType: "totalAccounts",
      startDate,
      endDate,
      aggregationType,
      compareToStartDate,
      compareToEndDate,
      lookBackPeriod: 5,
      forecastAggregationType,
    };
  }

  static getAutoLayoutConfig(): AutoLayoutConfig | null {
    return {
      widgetSize: [
        {
          viewportMinWidth: 0,
          configuration: () => {
            return {
              minWidth: "30 columns",
              minHeight: "15 rows",
            };
          },
        },
      ],
    };
  }

  static getSetterConfig(): SetterConfig {
    return {
      __setters: {
        setVisibility: {
          path: "isVisible",
          type: "boolean",
        },
      },
    };
  }

  onRevenueTypeChanged = () => {
    if (this.props.revenueType === "Forecast") {
      const { startDate, endDate, aggregationType } = getRevenueCardDates("Forecast");
      this.updateWidgetProperty("compareToStartDate", startDate);
      this.updateWidgetProperty("revenueType", "Forecast");
      this.updateWidgetProperty("compareToEndDate", endDate);
      this.updateWidgetProperty("aggregationType", "total");
      this.updateWidgetProperty("endDate", endDate);
      this.updateWidgetProperty("forecastAggregationType", "monthly");
      this.updateWidgetProperty("lookBackPeriod", 5);
      super.executeAction({
        triggerPropertyName: "onRevenueTypeChanged",
        dynamicString: this.props.onRevenueTypeChanged,
        event: {
          type: EventType.ON_CHANGE,
        },
        globalContext: {
          startDate: startDate || this.props.startDate,
          endDate: endDate || this.props.endDate,
          aggregationType: "total",
          compareToEndDate: endDate,
          compareToStartDate: startDate,
          lookBackPeriod: this.props.lookBackPeriod,
          forecastAggregationType: "monthly"
        },
      });
      return;
    }
    if (this.props.onRevenueTypeChanged) {
      const { startDate, endDate, aggregationType,
        compareToStartDate, compareToEndDate } = getRevenueCardDates(this.props.revenueType);
      this.updateWidgetProperty("startDate", startDate);
      this.updateWidgetProperty("revenueType", this.props.revenueType);
      this.updateWidgetProperty("endDate", endDate);
      this.updateWidgetProperty("aggregationType", aggregationType);
      this.updateWidgetProperty("compareToStartDate", compareToStartDate);
      this.updateWidgetProperty("compareToEndDate", compareToEndDate);
      super.executeAction({
        triggerPropertyName: "onRevenueTypeChanged",
        dynamicString: this.props.onRevenueTypeChanged,
        event: {
          type: EventType.ON_CHANGE,
        },
        globalContext: {
          startDate: startDate || this.props.startDate,
          endDate: endDate || this.props.endDate,
          aggregationType: aggregationType || this.props.aggregationType,
          compareToEndDate: compareToEndDate,
          compareToStartDate: compareToStartDate,
          lookBackPeriod: this.props.lookBackPeriod,
          forecastAggregationType: "monthly",
        },
      });
    }
  };

  static getPropertyPaneContentConfig() {
    return [
      {
        sectionName: "General",
        children: [
          {
            propertyName: "revenueType",
            label: "Revenue type",
            helpText: "Controls the font family being used",
            controlType: "DROP_DOWN",
            options: [
              {
                label: "Month to date",
                value: "MonthToDate",
              },
              {
                label: "Last Month Same Period",
                value: "LastMonthSamePeriod",
              },
              {
                label: "Last Month Total",
                value: "LastMonthTotal",
              },
              {
                label: "Last Year Total",
                value: "LastYearTotal",
              },
              {
                label: "Forecast",
                value: "Forecast",
              },
            ],
            defaultValue: "LastMonthTotal",
            hidden: (props: any) => props.cardType === "totalAccounts",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {
              type: ValidationTypes.TEXT,
            },
          },
          {
            propertyName: "cardType",
            label: "Analytics Card type",
            helpText: "Choose the metrics type",
            controlType: "DROP_DOWN",
            options: [
              {
                label: "Total Accounts",
                value: "totalAccounts",
              },
              {
                label: "Revenue",
                value: "revenue",
              },
            ],
            defaultValue: "totalAccounts",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {
              type: ValidationTypes.TEXT,
            },
          },
          {
            propertyName: "perviousMonthTotalAccounts",
            helpText: "Input JSON sample for default form layout",
            label: "Previous Month Total Accounts Source data",
            controlType: "ONE_CLICK_BINDING_CONTROL",

            ...ZAnalyticsCardWidget.queryDataConfig,
          },
          {
            propertyName: "currentMonthTotalAccounts",
            helpText: "Input JSON sample for default form layout",
            label: "Current Month Total Accounts Source data",
            controlType: "ONE_CLICK_BINDING_CONTROL",

            ...ZAnalyticsCardWidget.queryDataConfig,
          },
          {
            propertyName: "currentPeriodRevenue",
            helpText: "Input JSON sample for default form layout",
            label: "Current Period Revenue Source data",
            controlType: "ONE_CLICK_BINDING_CONTROL",

            ...ZAnalyticsCardWidget.queryDataConfig,
          },
          {
            propertyName: "previousPeriodRevenue",
            helpText: "Input JSON sample for default form layout",
            label: "Previous Period Revenue Source data",
            controlType: "ONE_CLICK_BINDING_CONTROL",

            ...ZAnalyticsCardWidget.queryDataConfig,
          },
          {
            propertyName: "forecastRevenue",
            helpText: "Input JSON sample for default form layout",
            label: "Forecasted Revenue Source data",
            controlType: "ONE_CLICK_BINDING_CONTROL",

            ...ZAnalyticsCardWidget.queryDataConfig,
          },
        ],
      },
      {
        sectionName: "event",
        children: [
          {
            helpText: "when Revenue type is changed",
            propertyName: "onRevenueTypeChanged",
            label: "When Revenue type is changed",
            controlType: "ACTION_SELECTOR",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: true,
          },
        ],
      },
    ];
  }

  static getStylesheetConfig(): Stylesheet {
    return {
      fillColor: "{{appsmith.theme.colors.primaryColor}}",
    };
  }

  static getPropertyPaneStyleConfig() {
    return [];
  }

  static getDerivedPropertiesMap(): DerivedPropertiesMap {
    return {};
  }

  static getDefaultPropertiesMap(): Record<string, string> {
    return {};
  }

  static getMetaPropertiesMap(): Record<string, any> {
    return {};
  }

  getWidgetView() {
    const ZAnalyticsCardWidgetProps = {
      ...this.props,
    };

    const isLoading = false;

    return (
      <ContainerWrapper>
        {!isLoading && (<AnalyticsCard
          {...ZAnalyticsCardWidgetProps}
          cardType={this.props.cardType}
          currentMonthTotalAccounts={
            this.props.currentMonthTotalAccounts?.data?.totalAccounts
          }
          currentPeriodRevenue={this.props.currentPeriodRevenue?.data}
          previousMonthTotalAccounts={
            this.props.perviousMonthTotalAccounts?.data?.totalAccounts
          }
          previousPeriodRevenue={this.props.previousPeriodRevenue?.data}
          revenueType={this.props.revenueType}
          onRevenueTypeChanged={this.onRevenueTypeChanged}
          forecastRevenue={this.props.forecastRevenue?.data}
        />)}
        {isLoading && (<Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '1.5rem',
            height: '108px',
            boxShadow: 1,
            borderRadius: 1,
            backgroundColor: 'white',
          }}
        >
          <Spinner />
        </Box>)}
      </ContainerWrapper>
    );
  }
}

export interface ZAnalyticsCardWidgetProps extends WidgetProps {
  size: number | string;
  thickness?: number;
  fillColor: string;
}

export default ZAnalyticsCardWidget;
