import { Box, Stack } from "@mui/material";
import React, { useState } from "react";
import {
  DURATION_SELECT,
  DURATION_TYPE,
} from "widgets/ZAnalytics/util/constants";
import { Select, SelectItem, Typography } from "zds";

type Props = {
  showSelector?: boolean;
  disabled?: boolean;
  currentDuration?: DURATION_TYPE;
  durationType?: DURATION_SELECT;
  onDurationSelect?: React.Dispatch<React.SetStateAction<DURATION_TYPE>>;
  onDurationTypeSelect?: React.Dispatch<
    React.SetStateAction<DURATION_SELECT | undefined>
  >;
  customerDetailsMeterUsage?: boolean;
  handleDurationSelection: any;
};

function getBorderColor(isSelected: boolean, disabled: boolean) {
  return isSelected
    ? disabled
      ? "rgba(0, 0, 0, 0.6)" // Darker grey for selected disabled box
      : "#0D4AC3"
    : "rgba(0, 0, 0, 0.43)";
}

function getRightBorderStyle(
  isRightSelected: boolean,
  isSelected: boolean,
  disabled: boolean,
) {
  return isRightSelected || isSelected
    ? disabled
      ? "2px solid rgba(0, 0, 0, 0.6)" // Darker grey for selected disabled box
      : "2px solid #0D4AC3"
    : "1px solid rgba(0, 0, 0, 0.43)";
}

function getBackgroundColor(disabled: boolean, isSelected: boolean) {
  return disabled
    ? "rgba(0, 0, 0, 0.1)"
    : isSelected
      ? "#E3F2FD"
      : "transparent";
}

type SelectBoxProps = {
  label: string;
  isSelected: boolean;
  position: any;
  isRightSelected: boolean;
  disabled: boolean;
  onClick: any;
};

const SelectableBox = ({
  label,
  isSelected,
  position,
  isRightSelected,
  disabled,
  onClick,
}: SelectBoxProps) => {
  const borderColor = getBorderColor(isSelected, disabled);
  const rightBorderStyle = getRightBorderStyle(
    isRightSelected,
    isSelected,
    disabled,
  );
  const backgroundColor = getBackgroundColor(disabled, isSelected);
  return (
    <Stack direction="row" spacing={1}>
      <Box
        sx={{
          width: 44,
          height: 32,
          border: `solid ${borderColor}`,
          borderWidth: isSelected ? "2px" : "1px",
          borderColor: borderColor,
          borderLeftWidth:
            position !== "start" ? "0px" : isSelected ? "2px" : "1px",
          borderRight: rightBorderStyle,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          cursor: disabled ? "not-allowed" : "pointer",
          backgroundColor: backgroundColor,
          "&:hover": {
            backgroundColor:
              !disabled && !isSelected ? "rgba(0, 0, 0, 0.1)" : backgroundColor,
          },
        }}
        onClick={onClick}
      >
        <Typography
          variant="body1"
          sx={{
            color: disabled
              ? "rgba(0, 0, 0, 0.3)"
              : isSelected
                ? "#0D4AC3"
                : "#000000",
          }}
        >
          {label}
        </Typography>
      </Box>
    </Stack>
  );
};

export default function DurationSelector({
  showSelector = true,
  disabled = false,
  currentDuration = DURATION_TYPE.twentyOneDays,
  durationType = DURATION_SELECT.daily,

  onDurationTypeSelect = undefined,
  customerDetailsMeterUsage = false,
  handleDurationSelection,
}: Props) {
  const [filterType, setFilterType] = useState<DURATION_SELECT>(durationType);
  const [selectedDuration, setSelectedDuration] = useState(currentDuration);
  const onDurationSelect = (
    duration: DURATION_TYPE | ((prevState: DURATION_TYPE) => DURATION_TYPE),
  ) => {
    setSelectedDuration(duration);
  };
  const handleSelectChange = (e: any) => {
    const filterType: DURATION_SELECT = e.target.value as DURATION_SELECT;
    if (filterType === "Monthly") {
      const durationType = customerDetailsMeterUsage
        ? DURATION_TYPE.twelveMonth
        : DURATION_TYPE.sixMonth;
      setSelectedDuration(durationType);
      onDurationSelect(durationType);
      handleDurationSelection(
        filterType.toLowerCase(),
        durationType,
        selectedDuration,
      );
    }
    if (filterType === "Weekly") {
      setSelectedDuration(DURATION_TYPE.twentyFourWeeks);
      onDurationSelect(DURATION_TYPE.twentyFourWeeks);
      handleDurationSelection(
        filterType.toLowerCase(),
        DURATION_TYPE.twentyFourWeeks,
        selectedDuration,
      );
    }
    if (filterType === "Daily") {
      setSelectedDuration(DURATION_TYPE.twentyOneDays);
      onDurationSelect(DURATION_TYPE.twentyOneDays);
      handleDurationSelection(
        filterType.toLowerCase(),
        DURATION_TYPE.twentyOneDays,
        selectedDuration,
      );
    }
    setFilterType(filterType);
    if (onDurationTypeSelect) {
      onDurationTypeSelect(filterType);
    }
  };

  const handleDurationSelect = (
    duration: DURATION_TYPE | ((prevState: DURATION_TYPE) => DURATION_TYPE),
  ) => {
    if (!disabled) {
      setSelectedDuration(duration);
      onDurationSelect(duration);
      handleDurationSelection(filterType.toLowerCase(), duration);
    }
  };

  return (
    <Stack direction="row" alignItems="center" spacing={2}>
      {showSelector && (
        <Box sx={{ width: "150px" }}>
          <Select
            sx={{
              height: "32px",
              borderWidth: "2px",
            }}
            dsOnChange={handleSelectChange}
            value={filterType}
            disabled={disabled}
          >
            <SelectItem value={DURATION_SELECT.daily}>
              <Typography variant="body1">{DURATION_SELECT.daily}</Typography>
            </SelectItem>
            <SelectItem value={DURATION_SELECT.weekly}>
              <Typography variant="body1">{DURATION_SELECT.weekly}</Typography>
            </SelectItem>
            <SelectItem value={DURATION_SELECT.monthly}>
              <Typography variant="body1">{DURATION_SELECT.monthly}</Typography>
            </SelectItem>
          </Select>
        </Box>
      )}
      {filterType === DURATION_SELECT.monthly && (
        <Stack direction="row" spacing={0}>
          <SelectableBox
            label={DURATION_TYPE.threeMonth}
            isSelected={selectedDuration === DURATION_TYPE.threeMonth}
            onClick={() => handleDurationSelect(DURATION_TYPE.threeMonth)}
            position="start"
            isRightSelected={selectedDuration === DURATION_TYPE.sixMonth}
            disabled={disabled}
          />
          <SelectableBox
            label={DURATION_TYPE.sixMonth}
            isSelected={selectedDuration === DURATION_TYPE.sixMonth}
            onClick={() => handleDurationSelect(DURATION_TYPE.sixMonth)}
            position=""
            isRightSelected={selectedDuration === DURATION_TYPE.twelveMonth}
            disabled={disabled}
          />
          <SelectableBox
            label={DURATION_TYPE.twelveMonth}
            isSelected={selectedDuration === DURATION_TYPE.twelveMonth}
            onClick={() => handleDurationSelect(DURATION_TYPE.twelveMonth)}
            position=""
            isRightSelected={false} // No box to the right
            disabled={disabled}
          />
        </Stack>
      )}

      {filterType === DURATION_SELECT.weekly && (
        <Stack direction="row" spacing={0}>
          <SelectableBox
            label={DURATION_TYPE.fourWeeks}
            isSelected={selectedDuration === DURATION_TYPE.fourWeeks}
            onClick={() => handleDurationSelect(DURATION_TYPE.fourWeeks)}
            position="start"
            isRightSelected={selectedDuration === DURATION_TYPE.eightWeeks}
            disabled={disabled}
          />
          <SelectableBox
            label={DURATION_TYPE.eightWeeks}
            isSelected={selectedDuration === DURATION_TYPE.eightWeeks}
            onClick={() => handleDurationSelect(DURATION_TYPE.eightWeeks)}
            position=""
            isRightSelected={selectedDuration === DURATION_TYPE.twelveWeeks}
            disabled={disabled}
          />
          <SelectableBox
            label={DURATION_TYPE.twelveWeeks}
            isSelected={selectedDuration === DURATION_TYPE.twelveWeeks}
            onClick={() => handleDurationSelect(DURATION_TYPE.twelveWeeks)}
            position=""
            isRightSelected={selectedDuration === DURATION_TYPE.twentyFourWeeks}
            disabled={disabled}
          />
          <SelectableBox
            label={DURATION_TYPE.twentyFourWeeks}
            isSelected={selectedDuration === DURATION_TYPE.twentyFourWeeks}
            onClick={() => handleDurationSelect(DURATION_TYPE.twentyFourWeeks)}
            position=""
            isRightSelected={false} // No box to the right
            disabled={disabled}
          />
        </Stack>
      )}

      {filterType === DURATION_SELECT.daily && (
        <Stack direction="row" spacing={0}>
          <SelectableBox
            label={DURATION_TYPE.sevenDays}
            isSelected={selectedDuration === DURATION_TYPE.sevenDays}
            onClick={() => handleDurationSelect(DURATION_TYPE.sevenDays)}
            position="start"
            isRightSelected={selectedDuration === DURATION_TYPE.fourteenDays}
            disabled={disabled}
          />
          <SelectableBox
            label={DURATION_TYPE.fourteenDays}
            isSelected={selectedDuration === DURATION_TYPE.fourteenDays}
            onClick={() => handleDurationSelect(DURATION_TYPE.fourteenDays)}
            position=""
            isRightSelected={selectedDuration === DURATION_TYPE.twentyOneDays}
            disabled={disabled}
          />
          <SelectableBox
            label={DURATION_TYPE.twentyOneDays}
            isSelected={selectedDuration === DURATION_TYPE.twentyOneDays}
            onClick={() => handleDurationSelect(DURATION_TYPE.twentyOneDays)}
            position=""
            isRightSelected={selectedDuration === DURATION_TYPE.twentyOneDays}
            disabled={disabled}
          />
        </Stack>
      )}
    </Stack>
  );
}
