import { Box } from "@mui/material";
import { Spinner } from "zds";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import React from "react";
import type { AnyAction } from "redux";
type VerticalBarChartProps = {
  data: any;
  loading: boolean;
};
const VerticalBarChart = ({ data, loading }: VerticalBarChartProps) => {
  const colors = ["#009684", "#0D4AC3", "#464C51", "#B36526", "#6F4DCD"];
  const options = {
    chart: {
      type: "column",
      height: 300,
    },
    title: {
      text: "",
    },
    xAxis: {
      categories: data?.map((item: any) =>
        item.productName ? item.productName : item.accountName,
      ),
      title: {
        text: null,
      },
      labels: {
        enabled: false, // Disable x-axis labels
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: "Billable Usage",
      },
    },
    series: data?.map((item: any, index: any) => {
      return {
        name: item.productName ? item.productName : item.accountName, // Create a separate series for each item
        data: [item.revenue], // Each series has one data point
        color: colors[index % colors.length], // Set color for each series
        meta: {
          [item.accountId ? "accountId" : "productId"]: item.accountId
            ? item.accountId
            : item.productId,
          [item.accountId ? "accountName" : "productName"]: item.accountName
            ? item.accountName
            : item.productName,
        },
      };
    }),
    plotOptions: {
      column: {
        borderRadius: 3,
        groupPadding: 0, // No padding between groups (fills width)
        pointPadding: 0, // Start with no padding between points
        pointWidth: 300 - 18 * (data.length - 1), // Calculate point width to ensure a 18px gap
      },
      series: {
        cursor: "pointer",
        point: {
          events: {},
        },
      },
    },
    legend: {
      enabled: true,
      align: "center", // Align legend at the center
      verticalAlign: "bottom", // Position legend at the bottom
      layout: "horizontal", // Horizontal layout for legends
    },
    credits: {
      enabled: false,
    },
    tooltip: {
      formatter: function (this: any) {
        return `<b>${this.point.series.name}</b><br/>Billable Usage: ${this.y}`;
      },
    },
  };

  // return (
  //   <>
  //     {loading ? (
  //       <Box
  //         style={{
  //           display: 'flex',
  //           alignItems: 'center',
  //           justifyContent: 'center',
  //           padding: '1.5rem',
  //         }}
  //       >
  //         <Spinner />
  //       </Box>
  //     ) : (
  //       <>
  //         <h1>SHOULD RENDER HERE</h1>
  //         <span>{JSON.stringify(options)}</span>
  //         <HighchartsReact highcharts={Highcharts} options={options} />
  //       </>
  //     )}
  //   </>
  // );
  return (
    <>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </>
  );
};
export default VerticalBarChart;
