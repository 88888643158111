import ActionAPI from "api/ActionAPI";
import { getLayoutSavePayload } from "ee/sagas/PageSagas";
import { getSavedDatasources } from "ee/selectors/entitiesSelector";
import { getCurrentWorkspaceId } from "ee/selectors/selectedWorkspaceSelectors";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { getEditorConfigs, getWidgets } from "sagas/selectors";
import { meterInsights } from "../api/MeterInsightsApi";
import type { ApiAction } from "entities/Action";
import PageApi from "api/PageApi";
import MetersSelector, { type MetersList } from "./MetersSelector";
import type { AnyAaaaRecord } from "dns";

type MeterInsightsProps = {
  onWidgetRender: any;
  meterList: MetersList;
  meterId: string;
  setDefaultMeterId: (merterId: string) => void;
  getSegmentation: any;
  segmentationData: any;
  mode: any;
  durationType: any;
};

const MeterInsightsBaseComponent = (props: MeterInsightsProps) => {
  const {
    onWidgetRender,
    meterList,
    meterId,
    setDefaultMeterId,
    getSegmentation,
    segmentationData,
    mode,
    durationType,
  } = props;
  const widgets = useSelector(getWidgets);
  const editorConfigs = useSelector(getEditorConfigs);
  const savePageRequest = getLayoutSavePayload(widgets, editorConfigs);
  const workspaceId = useSelector(getCurrentWorkspaceId);
  const datasources = useSelector(getSavedDatasources);
  const [zuoraInternalDataSource] = datasources.filter((ds) => {
    return ds.name === "Zuora Default Store";
  });
  const pageWidgets = Object.keys(widgets);
  const filteredWidgets = pageWidgets
    .filter((key) => {
      return widgets[key].type === "ZAnalyticsMeterInsights_WIDGET";
    })
    .map((key) => widgets[key]);

  const createQuery = (widgetId: string) => {
    if (
      editorConfigs &&
      editorConfigs?.pageId &&
      editorConfigs?.applicationId &&
      zuoraInternalDataSource
    ) {
      const timeStamp = Date.now();
      const { id, pluginId } = zuoraInternalDataSource;
      const apiMetaData: any[] = [
        {
          name: `segmentationData${timeStamp}`,
          httpMethod: "POST",
          path: "/analytics-consumption/meters/segmentation",
          queryParameters: [],
          body: "{{this.params.body}}",
          jsonPathKeys: ["this.params.body"],
        },
        {
          name: `metersData${timeStamp}`,
          httpMethod: "GET",
          path: "/analytics-consumption/meters",
          queryParameters: [],
          body: "{}",
          jsonPathKeys: [],
        },
      ];
      const payloads: any[] = apiMetaData.map(
        async ({
          body,
          httpMethod,
          jsonPathKeys,
          name,
          path,
          queryParameters,
        }) => {
          return ActionAPI.createAPIAction(
            meterInsights(
              editorConfigs?.pageId,
              editorConfigs?.applicationId,
              workspaceId,
              pluginId,
              id,
              name,
              httpMethod,
              path,
              body,
              jsonPathKeys,
              queryParameters,
            ),
          ) as Partial<ApiAction>;
        },
      );

      Promise.all([...payloads]).then(async (res) => {
        if (res) {
          const index = savePageRequest.dsl.children.findIndex(
            (child: { widgetId: string }) => child.widgetId === widgetId,
          );

          savePageRequest.dsl.children[index].metersData =
            `{{metersData${timeStamp}}}`;
          savePageRequest.dsl.children[index].segmentationData =
            `{{segmentationData${timeStamp}}}`;
          savePageRequest.dsl.children[index].dynamicBindingPathList = [
            ...savePageRequest.dsl.children[index].dynamicBindingPathList,
            { key: "metersData" },
            { key: "segmentationData" },
          ];
          savePageRequest.dsl.children[index].dynamicPropertyPathList = [
            { key: "metersData" },
            { key: "segmentationData" },
          ];
          savePageRequest.dsl.children[index].onLoad =
            `{{metersData${timeStamp}.run();}}`;
          savePageRequest.dsl.children[index].getSegmentation =
            `{{segmentationData${timeStamp}.run({\n  body: body});}}`;
          await PageApi.savePage(savePageRequest);
          const currentUrl = window.location.href;
          const editIndex = currentUrl.indexOf("/widgets");
          if (editIndex !== -1) {
            const newUrl = currentUrl.substring(0, editIndex);
            window.history.replaceState(null, "", newUrl);
            window.location.reload();
          }
        }
      });
    }
  };
  useEffect(() => {
    for (const widgetProps of filteredWidgets) {
      if (!widgetProps.metersData) {
        if (editorConfigs) createQuery(widgetProps.widgetId);
      } else {
        onWidgetRender();
      }
    }
  }, []);
  useEffect(() => {
    if (meterList.length && !meterId) {
      setDefaultMeterId(meterList[0].id);
    }
  }, [meterList]);
  return (
    <div>
      <MetersSelector
        meterList={meterList}
        selectedMeterId={meterId}
        getSegmentation={getSegmentation}
        segmentationData={segmentationData}
        mode={mode}
        durationType={durationType}
      />
    </div>
  );
};

export default MeterInsightsBaseComponent;
