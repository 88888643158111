import ActionAPI from "api/ActionAPI";
import type { ApiAction } from "entities/Action";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { getEditorConfigs, getWidgets } from "sagas/selectors";
import { revenueQueryPayload } from "../api/revenue";
import { getLayoutSavePayload } from "ee/sagas/PageSagas";
import { getCurrentWorkspaceId } from "ee/selectors/selectedWorkspaceSelectors";
import { getSavedDatasources } from "ee/selectors/entitiesSelector";
import PageApi from "api/PageApi";
import RevenueChart from "./RevenueChart";
import { calculateChange, formatDateRange, numberWithCommas } from "widgets/ZAnalytics/util/cardUtil";
import { Spinner } from "zds";
import { Box } from "@mui/material";

export type RevenueData = {
  currency: string;
  revenueByPeriod: Record<string, number>;
};
type Props = {
  onChartLoaded: () => void;
  revenueData: {
    data: RevenueData;
  };
  duration: any;
  isLoading: boolean;
  durationType: any;
  fetchCompareToData: any;
  compareToData: any;
  getForecast: any;
  forecastData: any;
  anomalyData: any;
  previousPeriodRevenue: any;
  currentPeriodRevenue: any;
  endDate: any;
  startDate: any;
  compareToStartDate: any;
  compareToEndDate: any;
  compareTo: any;
  setCompareTo: any;
  setShowForecast: any;
  showForecast: any;
};

const ForecastBaseComponent = (props: Props) => {
  const {
    onChartLoaded,
    duration,
    revenueData,
    isLoading,
    durationType,
    fetchCompareToData,
    compareToData,
    getForecast,
    forecastData,
    anomalyData,
    previousPeriodRevenue,
    currentPeriodRevenue,
    endDate,
    startDate,
    compareToStartDate,
    compareToEndDate,
    compareTo,
    setCompareTo,
    setShowForecast,
    showForecast,
  } = props;
  const editorConfigs = useSelector(getEditorConfigs);
  const [revenue, setRevenue] = useState<any>(null);
  const change = useMemo(() => {
    if (previousPeriodRevenue?.data?.revenue &&
      currentPeriodRevenue?.data?.revenue) {
      return calculateChange(
        previousPeriodRevenue.data.revenue,
        currentPeriodRevenue.data.revenue,
      );
    }
  }, [previousPeriodRevenue?.data?.revenue,
  currentPeriodRevenue?.data?.revenue]);
  useEffect(() => {
    if (change) {
      const dateText =
        'From ' + formatDateRange(compareToStartDate, compareToEndDate);

      const subtitle = formatDateRange(startDate, endDate);

      setRevenue({
        value: numberWithCommas(currentPeriodRevenue.data.revenue, true),
        change: change,
        dateText: dateText,
        subtitle: subtitle,
        currency: currentPeriodRevenue.data.currency,
      });
    }
  }, [change])
  const widgets = useSelector(getWidgets);
  const savePageRequest = getLayoutSavePayload(widgets, editorConfigs);
  const workspaceId = useSelector(getCurrentWorkspaceId);
  const datasources = useSelector(getSavedDatasources);
  const [zuoraInternalDataSource] = datasources.filter((ds) => {
    return ds.name === "Zuora Default Store";
  });
  const pageWidgets = Object.keys(widgets);
  const filteredWidgets = pageWidgets
    .filter((key) => {
      return widgets[key].type === "ZAnalyticForecast_Widget";
    })
    .map((key) => widgets[key]);

  const createQuery = async (widgetId: string) => {
    if (
      editorConfigs &&
      editorConfigs?.pageId &&
      editorConfigs?.applicationId &&
      zuoraInternalDataSource
    ) {
      const timeStamp = Date.now();
      const { id, pluginId } = zuoraInternalDataSource;
      const apiMetaData: any[] = [
        {
          name: `revenueData${timeStamp}`,
          httpMethod: "POST",
          path: "/{{this.params.path}}",
          queryParameters: [
            {
              key: "startDate",
              value: "{{this.params.startDate}}",
            },
            {
              key: "endDate",
              value: "{{this.params.endDate}}",
            },
            {
              key: "aggregationType",
              value: "{{this.params.aggregationType}}",
            },
            {
              key: "period",
              value: "{{this.params.period}}",
            },
          ],
          body: "{{this.params.payload}}",
          jsonPathKeys: [
            "this.params.period",
            "this.params.duration",
            "this.params.startDate",
            "this.params.endDate",
            "this.params.aggregationType",
            "this.params.payload",
          ],
        },
        {
          name: `revenueCompareToData${timeStamp}`,
          httpMethod: "POST",
          path: "/{{this.params.path}}",
          queryParameters: [
            {
              key: "startDate",
              value: "{{this.params.startDate}}",
            },
            {
              key: "endDate",
              value: "{{this.params.endDate}}",
            },
            {
              key: "aggregationType",
              value: "{{this.params.aggregationType}}",
            },
            {
              key: "period",
              value: "{{this.params.period}}",
            },
          ],
          body: "{{this.params.payload}}",
          jsonPathKeys: [
            "this.params.period",
            "this.params.duration",
            "this.params.compareToStartDate",
            "this.params.compareToEndDate",
            "this.params.startDate",
            "this.params.endDate",
            "this.params.aggregationType",
            "this.params.payload",
          ],
        },
        {
          name: `forecastData${timeStamp}`,
          httpMethod: "POST",
          path: "/{{this.params.forecastPath}}",
          queryParameters: [
            {
              key: "periods",
              value: "{{this.params.periods}}",
            },
            {
              key: "seasonality",
              value: "{{this.params.seasonality}}",
            },
            {
              key: "includeNegatives",
              value: "false",
            },
          ],
          body: "{{this.params.forecastPayload}}",
          jsonPathKeys: [
            "this.params.periods",
            "this.params.seasonality",
            "this.params.forecastPayload",
          ],
        },
        {
          name: `anomalyData${timeStamp}`,
          httpMethod: "POST",
          path: "/{{this.params.anomalyPath}}",
          body: "{{this.params.forecastPayload}}",
          jsonPathKeys: ["this.params.forecastPayload"],
        },
        {
          name: `currentPeriodRevenueData${timeStamp}`,
          path: "/analytics-consumption/usage/revenue",
          httpMethod: "POST",
          formData: {
            apiContentType: "application/json",
          },
          queryParameters: [
            {
              key: "aggregationType",
              value: "total",
            },
            { key: "startDate", value: "{{this.params.startDate}}" },
            { key: "endDate", value: "{{this.params.endDate}}" },
            { key: "period", value: "{{this.params.period}}" },
          ],
          body: "{accountIds:[],productIds:[]}",
          jsonPathKeys: [
            "this.params.startDate",
            "this.params.endDate",
            "this.params.aggregationType",
            "this.params.period",
          ],
        },
        {
          name: `previousPeriodRevenueData${timeStamp}`,
          path: "/analytics-consumption/usage/revenue",
          httpMethod: "POST",
          formData: {
            apiContentType: "application/json",
          },
          queryParameters: [
            {
              key: "aggregationType",
              value: "total",
            },
            { key: "startDate", value: "{{this.params.startDate}}" },
            { key: "endDate", value: "{{this.params.endDate}}" },
            { key: "period", value: "{{this.params.period}}" },
          ],
          body: "{accountIds:[],productIds:[]}",
          jsonPathKeys: [
            "this.params.startDate",
            "this.params.endDate",
            "this.params.aggregationType",
            "this.params.period",
          ],
        },
      ];
      const payloads: any[] = apiMetaData.map(
        async ({
          body,
          httpMethod,
          jsonPathKeys,
          name,
          path,
          queryParameters,
        }) => {
          return ActionAPI.createAPIAction(
            revenueQueryPayload(
              editorConfigs?.pageId,
              editorConfigs?.applicationId,
              workspaceId,
              pluginId,
              id,
              name,
              httpMethod,
              path,
              body,
              jsonPathKeys,
              queryParameters,
            ),
          ) as Partial<ApiAction>;
        },
      );

      Promise.all([...payloads]).then(async (res) => {
        if (res) {
          const index = savePageRequest.dsl.children.findIndex(
            (child: { widgetId: string }) => child.widgetId === widgetId,
          );
          savePageRequest.dsl.children[index].revenueData =
            `{{revenueData${timeStamp}}}`;
          savePageRequest.dsl.children[index].dynamicBindingPathList = [
            ...savePageRequest.dsl.children[index].dynamicBindingPathList,
            { key: "revenueData" },
            { key: "revenueCompareToData" },
            { key: "forecastData" },
            { key: "anomalyData" },
            { key: "currentPeriodRevenueData" },
            { key: "previousPeriodRevenueData" },
          ];
          savePageRequest.dsl.children[index].dynamicPropertyPathList = [
            { key: "revenueData" },
            { key: "revenueCompareToData" },
            { key: "forecastData" },
            { key: "anomalyData" },
            { key: "currentPeriodRevenueData" },
            { key: "previousPeriodRevenueData" },
          ];
          savePageRequest.dsl.children[index].onChartLoad =
            `{{
              revenueData${timeStamp}.run({\n  path: path,\n  period: period,\n  aggregationType: aggregationType,\n  startDate: startDate,\n  endDate: endDate,\n\tduration:duration,\n\tpayload:payload\n  // \"key\": \"value\",\n});
              currentPeriodRevenueData${timeStamp}.run({\n  startDate: startDate,\n endDate: endDate,\n \n aggregationType: aggregationType,\n period: period,}); \n
              previousPeriodRevenueData${timeStamp}.run({\n  startDate: compareToStartDate,\n endDate: compareToEndDate,\n \n aggregationType: aggregationType,\n period: period,}); \n
            }}`;
          savePageRequest.dsl.children[index].revenueCompareToData =
            `{{revenueCompareToData${timeStamp}}}`;
          savePageRequest.dsl.children[index].onCompareToChanged =
            `{{revenueCompareToData${timeStamp}.run({\n  path: path,\n  period: period,\n  aggregationType: aggregationType,\n  startDate: compareToStartDate,\n  endDate: compareToEndDate,\n\tduration:duration,\n\tpayload:payload\n  // \"key\": \"value\",\n});}}`;
          savePageRequest.dsl.children[index].anomalyData =
            `{{anomalyData${timeStamp}}}`;
          savePageRequest.dsl.children[index].onAnomalyEnabled =
            `{{anomalyData${timeStamp}.run({\n  anomalyPath: anomalyPath,\n forecastPayload:forecastPayload\n, // \"key\": \"value\",\n});}}`;
          savePageRequest.dsl.children[index].forecastData =
            `{{forecastData${timeStamp}}}`;
          savePageRequest.dsl.children[index].currentPeriodRevenueData =
            `{{currentPeriodRevenueData${timeStamp}}}`;
          savePageRequest.dsl.children[index].previousPeriodRevenueData =
            `{{previousPeriodRevenueData${timeStamp}}}`;
          savePageRequest.dsl.children[index].onForecastEnabled =
            `{{forecastData${timeStamp}.run({\n  forecastPath: forecastPath,\n  periods: periods,\n  seasonality: seasonality,\n  includeNegatives: false,\n\tforecastPayload:forecastPayload\n  // \"key\": \"value\",\n});}}`;
          await PageApi.savePage(savePageRequest);
          const currentUrl = window.location.href;
          const editIndex = currentUrl.indexOf("/widgets");
          if (editIndex !== -1) {
            const newUrl = currentUrl.substring(0, editIndex);
            window.history.replaceState(null, "", newUrl);
            window.location.reload();
          }
        }
      });
    }
  };

  useEffect(() => {
    for (const widgetProps of filteredWidgets) {
      if (!widgetProps.revenueData) {
        if (editorConfigs) createQuery(widgetProps.widgetId);
      } else {
        onChartLoaded();
      }
    }
  }, []);
  function convertApiResponseToSeriesData(data: {
    [key: string]: number;
  }): any[] {
    const seriesData: any[] = [];
    for (const [key, value] of Object.entries(data)) {
      const timestamp = new Date(key).getTime();
      seriesData.push([timestamp, value]);
    }
    return seriesData;
  }
  return (
    <>
      {isLoading && <Box
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '1.5rem',
        }}
      >
        <Spinner />
      </Box>}
      {revenueData && revenueData?.data?.revenueByPeriod && !isLoading && (
        <RevenueChart
          duration={duration}
          revenueData={revenueData.data.revenueByPeriod}
          durationType={durationType}
          onChartLoaded={onChartLoaded}
          fetchCompareToData={fetchCompareToData}
          getForecast={getForecast}
          forecastData={forecastData?.data}
          compareToData={compareToData?.data?.revenueByPeriod || []}
          anomalyData={convertApiResponseToSeriesData(anomalyData?.data || {})}
          revenue={revenue}
          compareTo={compareTo}
          setCompareTo={setCompareTo}
          setShowForecast={setShowForecast}
          showForecast={showForecast}
        />
      )}
    </>
  );
};

export default ForecastBaseComponent;
