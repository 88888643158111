import {
  ActionCreationSourceTypeEnum,
  PluginType,
  type ApiAction,
} from "entities/Action";

export interface Property {
  key: string;
  value: string;
}

export const riskOrOppPayload = (
  pageId: string,
  applicationId: string,
  workspaceId: string,
  pluginId: string,
  dataSourceId: string,
  name: string,
  httpMethod: string,
  path: string,
  body: JSON | string | Record<string, any> | null,
  jsonPathKeys: string[],
  queryParameters: Property[],
): Partial<ApiAction> => {
  return {
    applicationId,
    workspaceId,
    pluginType: PluginType.API,
    //pluginName: "Zuora Plugin",
    pluginId,
    datasource: {
      id: dataSourceId,
      userPermissions: [],
      name: "Zuora Default Store",
      pluginId,
      messages: [],
    },
    pageId: pageId,
    actionConfiguration: {
      timeoutInMillisecond: 10000,
      path: path,
      headers: [],
      autoGeneratedHeaders: [
        {
          key: "content-type",
          value: "application/json",
          isInvalid: false,
        },
      ],
      encodeParamsToggle: true,
      queryParameters,
      customConfiguration: {
        isCached: false,
        cachedTTL: 0,
      },
      body,
      bodyFormData: [],
      httpMethod,
      httpVersion: "HTTP11",
      preExecute: false,
      preExecuteTransform:
        "function preExecuteTransform(requestBody, request, sessionData, zConstants) {\n\tif(!request.url.includes(\"/consumption-insights\")) {\n\t\t throw new Error(\"Invalid URL\");\n\t}\n\treturn requestBody;\n}",
      postExecute: false,
      postExecuteTransform:
        'function postExecuteTransform(responseBody, request, sessionData, zConstants) {\n\treturn responseBody;\n}\nfunction sessionStorage(responseBody, request, sessionData, zConstants) {\n\treturn {"key": "value"};\n}',
      pluginSpecifiedTemplates: [
        {
          value: true,
        },
      ],
      formData: {
        apiContentType: "application/json",
      },
    },
    errorReports: [],
    executeOnLoad: false,
    dynamicBindingPathList: [
      {
        key: "path",
      },
      {
        key: "queryParameters[0].value",
      },
      {
        key: "body",
      },
      {
        key: "size",
      },
      {
        key: "limit",
      },
      {
        key: "startDate",
      },
      {
        key: "endDate",
      },
      {
        key: "aggregationType",
      },
      {
        key: "currencyIsoCode",
      },
    ],
    isValid: true,
    invalids: [],
    messages: [],
    jsonPathKeys,
    confirmBeforeExecute: false,
    userPermissions: [
      "read:actions",
      "delete:actions",
      "execute:actions",
      "manage:actions",
    ],
    source: ActionCreationSourceTypeEnum.SELF,
    name,
  };
};
