import React from "react";
import { Stack } from "@mui/material";
import HighchartsReact from "highcharts-react-official";
import { Card, Typography } from "zds";
import Highcharts from "highcharts";
import BillableUsage from "./BillableUsage";
import type {
  DURATION_SELECT,
  DURATION_TYPE,
} from "widgets/ZAnalytics/util/constants";

interface Props {
  chartType: string;
  content: any;
  size: any;
  fillColor: string;
  customerOrProductData: any;
  getAccountsOrProducts: any;
  riskOrOppData: any;
  getBillabelUsageOnAnalyzeChange: any;
  analyzeFilter: any;
  duration: DURATION_SELECT;
  durationType: DURATION_TYPE;
}

interface ChartProps {
  title: string;
  color: string;
  data: any;
  categories: any;
  chartType: string;
}

const ChartCard = ({
  categories,
  chartType,
  color,
  data,
  title,
}: ChartProps) => {
  const createChartOptions = (
    barColor: any,
    categories: any[],
    data: any,
    chartType: string,
  ) => {
    if (chartType === "risk" || chartType === "opportunity") {
      return {
        chart: {
          type: "bar",
          marginRight: 50, // Add margin to the right side of the chart
          marginLeft: 20, // Add margin to the left side of the chart
          height: 294,
        },
        title: {
          text: null,
        },
        xAxis: {
          categories: categories.map((category) => category.name), // Display only names on the x-axis
          labels: {
            useHTML: true,
            formatter: function (this: { value: string }): string {
              return `<div style="position: absolute; top: -21px; left:15px;">${this.value}</div>`;
            },
            style: {
              color: "#000000",
              fontSize: "14px",
              fontWeight: "normal",
            },
          },
          title: {
            text: null,
          },
          lineWidth: 0,
          maxPadding: 0,
          minPadding: 0,
        },
        yAxis: {
          min: 0,
          max: 100,
          title: {
            text: "",
            align: "high",
          },
          labels: {
            enabled: false,
          },
          gridLineWidth: 0,
          lineWidth: 0,
        },
        plotOptions: {
          series: {
            stacking: "normal",
          },
          bar: {
            dataLabels: {
              enabled: true,
              align: "right",
              verticalAlign: "top",
              inside: false,
              style: {
                color: "#000000",
                fontSize: "14px",
                fontWeight: "normal",
              },
              formatter: function () {
                return `${this.y}`;
              },
              x: 9999,
              y: -23,
            },
            color: barColor,
            borderWidth: 0,
            pointWidth: 8,
            cursor: "pointer", // Show pointer cursor on hover
          },
        },
        legend: {
          enabled: false,
        },
        series: [
          {
            name: "Background",
            data: new Array(categories.length).fill(100),
            color: "#F1F5F8",
            enableMouseTracking: false,
            showInLegend: false,
            dataLabels: {
              enabled: false,
            },
          },
          {
            name: "Score",
            data: data,
          },
        ],
        credits: {
          enabled: false,
        },
      };
    }
    if (chartType === "accounts" || chartType === "products") {
      const options = {
        chart: {
          type: "column",
          height: 300,
          events: {},
        },
        title: {
          text: "",
        },
        xAxis: {
          categories: categories,
          title: {
            text: "",
          },
        },
        yAxis: {
          min: 0,
          title: {
            text: "Billable Usage",
          },
        },
        series: [
          {
            name: "Billable Usage",
            data: data,
            color: barColor,
          },
        ],
        plotOptions: {
          column: {
            borderRadius: 3,
          },
        },
        credits: {
          enabled: false,
        },
      };
      return options;
    }
    return {};
  };

  const options = createChartOptions(color, categories, data, chartType);

  return (
    <Card
      body={
        // eslint-disable-next-line react-perf/jsx-no-jsx-as-prop
        <Stack>
          <Typography
            // eslint-disable-next-line react-perf/jsx-no-new-object-as-prop
            sx={{ paddingLeft: "27px", marginTop: "-20px" }}
            variant="caption"
          >
            {"Scores based on the last 12 months"}
          </Typography>
          <HighchartsReact highcharts={Highcharts} options={options} />
        </Stack>
      }
      fullWidth
      // eslint-disable-next-line react-perf/jsx-no-jsx-as-prop
      header={<Typography variant="h6">{title}</Typography>}
      id="chart-card"
      titleBar
    />
  );
};

const ChartRenderer = React.memo(function ChartRenderer({
  chartType,
  content,
  fillColor,
  size,
  customerOrProductData,
  getAccountsOrProducts,
  riskOrOppData,
  getBillabelUsageOnAnalyzeChange,
  analyzeFilter,
  duration,
  durationType,
}: Props) {
  const categories = React.useMemo(() => {
    if (["risk", "opportunity"].includes(chartType)) {
      return content?.map((item: { account_id: any; account_name: any }) => ({
        id: item.account_id,
        name: item.account_name,
      }));
    }
    return content?.map((item: { productName: any; accountName: any }) =>
      item?.productName ? item?.productName : item?.accountName,
    );
  }, [chartType, content]);

  const data = React.useMemo(() => {
    if (["risk", "opportunity"].includes(chartType)) {
      return content?.map((item: { score: any }) => item.score);
    }
    return content?.map((item: { revenue: any }) => item.revenue);
  }, [chartType, content]);
  let title = null;
  if (chartType === "opportunity") {
    title = `Top ${size} opportunities`;
  } else if (chartType === "risk") {
    title = `Top ${size} risk`;
  } else if (chartType === "accounts") {
    title = `Top ${size} Performing Customer Accounts`;
  } else if (chartType === "products") {
    title = `Top ${size} Performing Products`;
  }
  if (chartType === "usageBreakdown") {
    return (
      <BillableUsage
        customerOrProductData={customerOrProductData}
        riskOrOppData={riskOrOppData}
        getBillabelUsageOnAnalyzeChange={getBillabelUsageOnAnalyzeChange}
        analyzeFilter={analyzeFilter}
        duration={duration}
        durationType={durationType}
        getAccountsOrProducts={getAccountsOrProducts}
      />
    );
  }
  return (
    <div>
      {data && data.length ? (
        <ChartCard
          categories={categories}
          chartType={chartType}
          color={fillColor}
          data={data}
          title={`${title}`}
        />
      ) : (
        <h1>Loading IN COMPONENT</h1>
      )}
    </div>
  );
});

export default ChartRenderer;
