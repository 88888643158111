import ActionAPI from "api/ActionAPI";
import PageApi from "api/PageApi";
import { getLayoutSavePayload } from "ee/sagas/PageSagas";
import { getSavedDatasources } from "ee/selectors/entitiesSelector";
import { getCurrentWorkspaceId } from "ee/selectors/selectedWorkspaceSelectors";
import type { ApiAction } from "entities/Action";
import React, { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { getEditorConfigs, getWidgets } from "sagas/selectors";
import { riskOrOppPayload } from "widgets/ZAnalytics/ZAnanlyticsChart/api/riskOrOpp";
import AccountInsights from "./AccountInsights";

type Props = {
  riskOrOppData: any;
  onTableLoaded: any;
  oppLoading: any;
  tableType: "opp" | "risk";
  getCountryListData: any;
  countryData: any;
};

const AccountInsightsBaseComponent = (props: Props) => {
  const {
    riskOrOppData,
    onTableLoaded,
    oppLoading,
    tableType,
    getCountryListData,
    countryData,
  } = props;
  const { data, totalElements } = useMemo(() => {
    if (riskOrOppData?.data?.content) {
      return {
        data: riskOrOppData?.data?.content,
        totalElements: riskOrOppData.data?.totalElements,
      };
    }
    return {
      data: [],
      totalElements: 0,
    };
  }, [riskOrOppData?.data?.content]);

  useEffect(() => {
    onTableLoaded(tableType);
  }, [tableType]);
  const widgets = useSelector(getWidgets);
  const editorConfigs = useSelector(getEditorConfigs);
  const savePageRequest = getLayoutSavePayload(widgets, editorConfigs);
  const workspaceId = useSelector(getCurrentWorkspaceId);
  const pageWidgets = Object.keys(widgets);
  const datasources = useSelector(getSavedDatasources);
  const [zuoraInternalDataSource] = datasources.filter((ds) => {
    return ds.name === "Zuora Default Store";
  });
  const filteredWidgets = pageWidgets
    .filter((key) => {
      return widgets[key].type === "ZANALYTICSTABLE_WIDGET";
    })
    .map((key) => widgets[key]);

  const createQuery = (widgetId: string) => {
    if (
      editorConfigs &&
      editorConfigs?.pageId &&
      editorConfigs?.applicationId &&
      zuoraInternalDataSource
    ) {
      const timeStamp = Date.now();
      const { id, pluginId } = zuoraInternalDataSource;
      const apiMetaData: any[] = [
        {
          name: `riskOrOppData${timeStamp}`,
          httpMethod: "POST",
          path: "/{{this.params.url}}",
          queryParameters: [
            {
              key: "page",
              value: "{{this.params.page}}",
            },
            {
              key: "size",
              value: "{{this.params.size}}",
            },
          ],
          body: "{{this.params.body}}",
          jsonPathKeys: [
            "this.params.url",
            "this.params.page",
            "this.params.size",
            "this.params.body",
          ],
        },
        {
          name: `countryData${timeStamp}`,
          httpMethod: "GET",
          path: "/analytics-consumption/usage/score/opp/country",
          queryParameters: [],
          body: "{}",
          jsonPathKeys: [],
        },
      ];
      const payloads: any[] = apiMetaData.map(
        async ({
          body,
          httpMethod,
          jsonPathKeys,
          name,
          path,
          queryParameters,
        }) => {
          return ActionAPI.createAPIAction(
            riskOrOppPayload(
              editorConfigs?.pageId,
              editorConfigs?.applicationId,
              workspaceId,
              pluginId,
              id,
              name,
              httpMethod,
              path,
              body,
              jsonPathKeys,
              queryParameters,
            ),
          ) as Partial<ApiAction>;
        },
      );

      Promise.all([...payloads]).then(async (res) => {
        if (res) {
          const index = savePageRequest.dsl.children.findIndex(
            (child: { widgetId: string }) => child.widgetId === widgetId,
          );
          savePageRequest.dsl.children[index].riskOrOppData =
            `{{riskOrOppData${timeStamp}}}`;
          savePageRequest.dsl.children[index].countryData =
            `{{countryData${timeStamp}}}`;
          savePageRequest.dsl.children[index].dynamicBindingPathList = [
            ...savePageRequest.dsl.children[index].dynamicBindingPathList,
            { key: "riskOrOppData" },
            { key: "countryData" },
          ];
          savePageRequest.dsl.children[index].dynamicPropertyPathList = [
            { key: "riskOrOppData" },
            { key: "countryData" },
          ];
          savePageRequest.dsl.children[index].getCountryList =
            `{{countryData${timeStamp}.run();}}`;
          savePageRequest.dsl.children[index].onTableTypeChanged =
            `{{riskOrOppData${timeStamp}.run({\n  url: url,\n  size: size,\n  body: body,\n  page: page,\n});}}`;
          await PageApi.savePage(savePageRequest);
          const currentUrl = window.location.href;
          const editIndex = currentUrl.indexOf("/widgets");
          if (editIndex !== -1) {
            const newUrl = currentUrl.substring(0, editIndex);
            window.history.replaceState(null, "", newUrl);
            window.location.reload();
          }
        }
      });
    }
  };

  useEffect(() => {
    for (const widgetProps of filteredWidgets) {
      if (!widgetProps.riskOrOppData) {
        if (editorConfigs) createQuery(widgetProps.widgetId);
      } else {
        getCountryListData();
        onTableLoaded();
      }
    }
  }, []);
  return (
    <div>
      <AccountInsights
        oppRows={[...data]}
        tableType={tableType}
        onTableLoaded={onTableLoaded}
        oppCountriesList={countryData?.data || []}
        oppLoading={oppLoading}
        oppTotalElements={totalElements}
      />
    </div>
  );
};

export default AccountInsightsBaseComponent;
